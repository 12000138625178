import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { Swiper, SwiperSlide } from '@gc/ui-components/swiper'
import { useResponsive } from 'ahooks'
import { isEmpty } from 'lodash'

type HeroTile = {
  imageSrc: string
  altText: string
  href: string
}

type HeroTilesProps = {
  source: HeroTile[]
  actionId: string
}

const HeroTiles: React.FC<HeroTilesProps> = ({ source, actionId }) => {
  const responsive = useResponsive()
  if (isEmpty(source)) return null
  const smallTiles = source.slice(2, 5)
  const isMobile = !responsive?.mobileForHomePageHeroTiles

  return (
    <div
      className="relative border-[#e7e7e7] mx-auto max-w-[1500px] mb-5"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'HeroTiles'}
    >
      <Link href={source[0].href ?? source[1].href}>
        <picture>
          <source media={`(max-width: 666px)`} srcSet={source[0]?.imageSrc} />
          <source media={`(min-width: 667px)`} srcSet={source[1]?.imageSrc} />
          <Image
            height={isMobile ? 548 : 415}
            width={isMobile ? 974 : 1500}
            src={source[0].imageSrc ?? source[1].imageSrc}
            alt={source[0].altText ?? source[1].altText}
            className="mb-5 w-full h-auto"
            priority
            loading="eager"
            sizes="100vw"
            suppressHydrationWarning
          />
        </picture>
      </Link>
      <Swiper
        spaceBetween={20}
        slidesPerView={1.04}
        scrollbar={{ hide: true }}
        freeMode={false}
        direction="horizontal"
        mousewheel={{
          enabled: true,
          forceToAxis: true
        }}
        loop={false}
        breakpoints={{
          [GC_BREAK_POINTS_CONFIG.small]: {
            slidesPerView: 1.6
          },
          [GC_BREAK_POINTS_CONFIG.middle]: {
            slidesPerView: 2.15
          },
          [GC_BREAK_POINTS_CONFIG.legacy]: {
            slidesPerView: 3
          }
        }}
      >
        {smallTiles.map((tile, index) => (
          <SwiperSlide key={index}>
            <Link href={tile.href}>
              <Image
                src={tile.imageSrc}
                alt={tile.altText}
                width={484}
                height={276}
                loading={
                  isMobile && index === smallTiles.length - 1 ? 'lazy' : 'eager'
                }
                priority={
                  isMobile && index === smallTiles.length - 1 ? false : true
                }
                sizes="100vw"
                className="w-full h-auto"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default HeroTiles
