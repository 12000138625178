import type { IProductStickerProps } from '@bc/global/product-sticker'
import { IMAGE_PREFIX } from '@constants/media'

import HomepageCarouselSection from '../carousel-section'
import { getSkeletonClassName } from '../skeletons/CarouselSectionSkeleton'
import NoClientData from '../skeletons/NoClientData'

export const mapAlgoliaHits = (hits: any) => {
  // eslint-disable-next-line
  return hits.map(hit => {
    const imageLink = `${IMAGE_PREFIX}MMGS7/${hit.imageId}-00-200x200.jpg`
    const link = hit.seoUrl ?? `/product-detail-page?skuId=${hit.skuId}`
    const title = hit.displayName
    const altText = hit.displayName
    const price = hit.listPrice
    const salePrice = hit.price
    const storeName = hit.storeName
    const description = ''

    let financing = ''
    const easyPayOption = hit.easyPayOption

    if (easyPayOption) {
      const { months, specialFinancing } = easyPayOption
      if (months && months > 0) {
        const monthlyPayment = (price / months).toFixed(2)
        financing = `$${monthlyPayment}/mo.‡ with ${months}-month financing*`
      } else if (specialFinancing) {
        financing = 'Special Financing Available'
      }
    }

    let stickers: IProductStickerProps[] = []
    if (hit.sticker && hit.sticker.length > 0) {
      stickers = hit.sticker.map((stickerItem: any) => ({
        stickerType: stickerItem.name,
        stickerClass: stickerItem.className,
        className: `p-1.5 font-semibold ${stickerItem.type === 'top' ? 'absolute top-2 start-2' : ''}`
      }))
    }

    // Extract review information
    const reviewStars = hit.review?.overallRating || 0
    const reviewCount = hit.review?.totalReviews || 0

    const usedCondition = hit.condition?.lvl0
    const openBoxStyleOptions = hit.allSkuProperties?.filter(
      sku => sku.condition === 'Open Box'
    )

    // Extract product ICID if available
    const productIcid = hit.productIcid || ''

    return {
      imageLink,
      link,
      title,
      altText,
      price,
      salePrice,
      description,
      financing,
      openBoxStyleOptions,
      stickers,
      storeName,
      reviewStars,
      reviewCount,
      usedCondition,
      productIcid
    }
  })
}

export default function ServerAlgoliaProductCarousel({
  carouselSettings,
  source,
  title,
  slide,
  viewAllUrl,
  actionId,
  productIcid
}) {
  if (!source?.results) {
    return <NoClientData className={getSkeletonClassName(slide)} />
  }

  return (
    <HomepageCarouselSection
      slide={slide}
      title={title}
      source={mapAlgoliaHits(source.results.hits)}
      viewAllUrl={viewAllUrl}
      carouselSettings={carouselSettings}
      actionId={actionId}
      productIcid={productIcid}
    />
  )
}
