import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { formatCurrency } from '@gc/common-lib/utils/currency'
import { openBoxConditions } from '@helper/business/algolia-plp/product-item'
import { groupBy, isEmpty, reduce } from 'lodash'

function ProductCondition({
  openBoxStyleOptions,
  className
}: {
  openBoxStyleOptions: any
  className?: string
}) {
  const groupedStyles = groupBy(openBoxStyleOptions, 'condition')

  return (
    <div
      className={
        className
          ? `plp-product-condition inline-block ${className}`
          : 'plp-product-condition inline-block mb-2'
      }
    >
      {openBoxConditions.map(condition => {
        if (isEmpty(groupedStyles[condition])) {
          return null
        }

        const { isMultiplePrice, lowestPriceItem } = reduce(
          groupedStyles[condition],
          (acc, item) => {
            if (!acc.lowestPriceItem) {
              return { isMultiplePrice: false, lowestPriceItem: item }
            }

            if (item.price < acc.lowestPriceItem.price) {
              acc.lowestPriceItem = item
            }

            if (
              !acc.isMultiplePrice &&
              item.price !== acc.lowestPriceItem.price
            ) {
              acc.isMultiplePrice = true
            }

            return acc
          },
          {} as { isMultiplePrice: boolean; lowestPriceItem: any }
        )

        const {
          skuId,
          price,
          seoUrl = `/product-detail-page?skuId=${skuId}`
        } = lowestPriceItem

        return (
          <div key={condition} className="font-semibold text-xs text-[#2D2D2D]">
            <a href={seoUrl} className="condition-name underline ">
              {condition}:
            </a>
            <span className="condition-price ml-1">
              {formatCurrency(price, '$')}
              {isMultiplePrice && '+'}
            </span>
          </div>
        )
      })}
    </div>
  )
}
export default withErrorBoundary(ProductCondition)
