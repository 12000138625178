const NoClientData: React.FC<{ className: string }> = ({ className }) => (
  <div
    className={`flex bg-gray-200 p-5 rounded-lg items-center justify-center ${className}`}
  >
    <div className="bg-white rounded-full shadow-lg h-fit p-5">
      Uh-oh! Looks like we're having trouble loading this section. Please try
      again later.
    </div>
  </div>
)

export default NoClientData
