import { GC_DRAWER_HEADER_STORE_LIST } from '@constants/global'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'

type LocalStoreProps = {
  header: string
  ctaText: string
  actionId: string
  homeStore?: {
    name: string
    address: string
    city: string
    state: string
    zipCode: string
  } // Optional prop for Storybook
}

const LocalStore: React.FC<LocalStoreProps> = ({
  header,
  ctaText,
  actionId,
  homeStore: storybookHomeStore // Use if STORYBOOK is true
}) => {
  const userInfo = useHeaderUserInfo()
  const { changeDrawer } = useChangeDrawerArr()

  // Use the mock homeStore if in Storybook
  const homeStore =
    process.env.STORYBOOK === 'true' ? storybookHomeStore : userInfo.homeStore
  if (!header || !ctaText) return
  return (
    <div
      className="flex flex-col legacy:flex-row justify-between items-center border border-gray-300 w-full md:w-fit max-w-[calc(100%-40px)] legacy:w-full md:max-w-[1024px] mx-auto mt-12 mb-3 pb-1 "
      data-monetate-action-id={actionId}
      data-monetate-component-name={'LocalStore'}
    >
      <div
        className={`${homeStore?.address ? '' : 'text-center'} p-5 text-base font-semibold order-2 legacy:order-1`}
      >
        <p className="font-bold mb-0">{header}</p>
        {homeStore?.address && (
          <>
            <div className="uppercase">{homeStore.name}</div>
            <div className="text-base">
              {homeStore.address}
              <div>
                {homeStore.city}, {homeStore.state} {homeStore.zipCode}
              </div>
            </div>
          </>
        )}
        <button
          className="bg-[#E7131A] text-white font-semibold py-2 px-4 rounded mt-2 inline-block"
          onClick={() => {
            changeDrawer(GC_DRAWER_HEADER_STORE_LIST)
          }}
        >
          {ctaText}
        </button>
      </div>
      <div className="w-full md:w-[603px] order-1 legacy:order-2">
        <Image
          src={'/assets/images/home/store.jpg'}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '603px'
          }}
          width={603}
          height={216}
          alt={
            'Shop your local Guitar Center store. Image of the inside of a Guitar Center store.'
          }
        />
      </div>
    </div>
  )
}

export default LocalStore
