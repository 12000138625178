import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

const FullWidthAsset = ({
  imageSrc,
  mobileImageSrc,
  tabletImageSrc,
  imageAltText,
  href,
  actionId,
  title,
  description,
  ctaText
}) => {
  if (!href) return
  return (
    <Link
      href={href}
      className="max-w-[1492px] mx-auto block relative mb-5 px-5 py-5"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'FullWidthAsset'}
    >
      <picture>
        <source srcSet={mobileImageSrc} media="(max-width: 767px)" />
        <source srcSet={tabletImageSrc} media="(max-width: 975px)" />
        <Image
          height={506}
          width={1492}
          className="mx-auto w-full h-auto"
          sizes="100vw"
          src={imageSrc}
          alt={imageAltText}
        />
      </picture>
      {(title || description || ctaText) && (
        <div className="flex items-center flex-col text-white bg-[#543121] text-center p-[3%] md:items-start md:text-left md:p-0 mb-5 md:mb-0 md:bg-transparent md:top-1/2 md:-translate-y-1/2 md:absolute  md:left-[6%] min-[1492px]:left-[3%]">
          {title && <h3 className="text-2xl text-white font-bold">{title}</h3>}
          {description && (
            <p className="max-w-[184px] md:max-w-[240px] font-normal text-base">
              {description}
            </p>
          )}
          {ctaText && (
            <button className="bg-[#e7131a] border p-[2.5%] font-semibold mt-[5%] rounded">
              {ctaText}
            </button>
          )}
        </div>
      )}
    </Link>
  )
}

export default FullWidthAsset
