import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { triggerChatButton } from '@gc/common-lib/utils/liveChat'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

type Advantage = {
  imageSrc: string
  altText: string
  title: string
}

type ExpertAdvice = {
  text: string
  phone: {
    number: string
    ariaLabel: string
    href: string
  }
  liveChat: {
    text: string
    icon: string // For SVG icon, it could be React component
  }
}

type WhyShopAtGuitarCenterProps = {
  heading: string
  subheading: string
  cta: {
    text: string
    href: string
  }
  advantages: Advantage[]
  expertAdvice: ExpertAdvice
  actionId: string
}

const WhyShopAtGuitarCenter: React.FC<WhyShopAtGuitarCenterProps> = ({
  heading,
  subheading,
  cta,
  advantages,
  expertAdvice,
  actionId
}) => {
  const userInfo = useHeaderUserInfo()
  const phone = userInfo?.callCenterNumber ?? '866-498-7882'
  if (!advantages) return
  return (
    <section
      className="bg-[#f9f9f9] mb-5 max-w-[1492px] mx-auto"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'WhyShopAtGuitarCenter'}
    >
      <div className="flex flex-col justify-center items-center p-[2.66%] text-center">
        <h1 className="text-[calc(26px+10*((100vw-375px)/1125))] legacy:text-4xl font-black text-[#E7131A]">
          {heading}
        </h1>
        <h2 className="text-[calc(18px+6*((100vw-375px)/1125))] legacy:text-2xl font-bold mt-[2%] text-gray-700 w-[92%] sm:w-[61%] mb-[3%] sm:mb-0">
          {subheading}
        </h2>
        <Link
          href={cta.href}
          className="bg-[#E7131A] text-white font-semibold text-base w-[202px] py-4 px-3 rounded mb-[6%] mt-[2%] sm:my-[2%] inline-block"
        >
          {cta.text}
        </Link>
        <ul className="flex flex-wrap w-[94%] sm:w-[70%] justify-evenly items-center font-semibold my-[2%]">
          {advantages.map((advantage, index) => (
            <li key={index} className="flex flex-col items-center w-[170px]">
              <Image
                src={advantage.imageSrc}
                alt={advantage.altText}
                className="w-20 h-20 mb-1.5"
                width={80}
                height={80}
              />
              <div className="font-semibold text-base">{advantage.title}</div>
            </li>
          ))}
        </ul>
        <div className="flex flex-wrap xl:flex-nowrap gap-x-12 gap-y-4 bg-white items-center justify-center w-[92%] sm:w-[77%] p-6 border border-gray-300">
          <div className="text-[calc(14px+4*((100vw-375px)/1125))] 2xl:text-lg basis-full xl:basis-auto font-bold">
            {expertAdvice.text}
          </div>

          <Link
            href={`tel:${phone}`}
            className="flex items-center text-[#E7131A] font-bold border border-[#E7131A] px-4 py-2 rounded max-w-[262px] basis-full justify-center sm:basis-auto"
            aria-label={phone + ' Phone Number for Guitar Center Sales'}
          >
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              role="presentation"
            >
              <path
                d="M3.69494 3.57484H7.23042L8.36998 6.42375L6.53932 7.64419C6.32027 7.79023 6.18869 8.03609 6.18869 8.29937C6.19069 8.37309 6.18869 8.30016 6.18869 8.30016L6.18869 8.30098L6.18869 8.30274L6.18872 8.30671L6.18883 8.31643C6.18895 8.32369 6.18915 8.3325 6.18948 8.34283C6.19015 8.36347 6.19135 8.39016 6.19351 8.42249C6.19782 8.48713 6.20594 8.57452 6.22121 8.6814C6.25172 8.89502 6.31096 9.18767 6.42595 9.53265C6.65685 10.2253 7.11054 11.1222 7.99416 12.0058C8.87777 12.8895 9.77465 13.3432 10.4674 13.5741C10.8123 13.689 11.105 13.7483 11.3186 13.7788C11.4255 13.7941 11.5129 13.8022 11.5775 13.8065C11.6098 13.8087 11.6365 13.8099 11.6572 13.8105C11.6675 13.8109 11.6763 13.8111 11.6836 13.8112L11.6933 13.8113L11.6973 13.8113L11.699 13.8113L11.6998 13.8113C11.6998 13.8113 11.7877 13.8065 11.7006 13.8113C11.9989 13.8113 12.2715 13.6428 12.4049 13.376L12.9323 12.3214L16.4252 12.9035V16.3051C14.7628 16.5455 10.2734 16.7824 6.74548 13.2545C3.2176 9.72664 3.45454 5.23716 3.69494 3.57484ZM7.82152 8.68212L9.24355 7.7341C9.8632 7.321 10.1088 6.53033 9.83219 5.83887L8.69262 2.98996C8.45346 2.39206 7.87438 2 7.23042 2H3.6538C2.93842 2 2.26504 2.49699 2.1476 3.27318C1.88042 5.03905 1.51675 10.253 5.6319 14.3681C9.74704 18.4833 14.9609 18.1196 16.7268 17.8524C17.503 17.735 18 17.0616 18 16.3462V12.9035C18 12.1337 17.4434 11.4767 16.6841 11.3501L13.1912 10.768C12.5092 10.6543 11.8329 10.9987 11.5237 11.6171L11.2508 12.1628C11.1651 12.1417 11.0694 12.1147 10.9654 12.08C10.4769 11.9172 9.79897 11.5835 9.10774 10.8923C8.41651 10.201 8.08279 9.52307 7.91998 9.03464C7.87598 8.90266 7.84431 8.78398 7.82152 8.68212Z"
                fill="#E7131A"
              />
            </svg>
            {phone}
          </Link>
          <button
            className="flex items-center text-[#E7131A] font-bold border border-[#E7131A] px-4 py-2 rounded max-w-[262px] basis-full justify-center sm:basis-auto"
            onClick={() => triggerChatButton()}
          >
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              role="presentation"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.53353 7.44137C4.53353 4.20191 7.05302 2.2002 10.0002 2.2002C12.9474 2.2002 15.4669 4.20191 15.4669 7.44137V7.57132C16.833 7.94412 17.8002 9.2493 17.8002 10.7355C17.8002 12.2757 16.7615 13.6215 15.3164 13.9366L14.9622 15.4364C14.7106 16.5021 13.788 17.3002 12.6745 17.3002H10.778C10.3361 17.3002 9.97797 16.942 9.97797 16.5002C9.97797 16.0584 10.3361 15.7002 10.778 15.7002H12.6745C12.9887 15.7002 13.3104 15.4695 13.4051 15.0688L13.6677 13.9564C12.8648 13.7599 12.3113 13.0066 12.3113 12.1767V9.29431C12.3113 8.39741 12.9578 7.58995 13.8669 7.47809V7.44137C13.8669 5.22296 12.2077 3.8002 10.0002 3.8002C7.79272 3.8002 6.13353 5.22296 6.13353 7.44137V7.47809C7.04257 7.58995 7.68908 8.39741 7.68908 9.29431V12.1767C7.68908 13.1433 6.9381 14.0061 5.91686 14.0061H5.33353C3.56057 14.0061 2.2002 12.4981 2.2002 10.7355C2.2002 9.2493 3.16736 7.94412 4.53353 7.57132V7.44137ZM5.33353 9.0649C4.52916 9.0649 3.8002 9.76915 3.8002 10.7355C3.8002 11.7018 4.52916 12.4061 5.33353 12.4061H5.91686C5.94915 12.4061 5.9865 12.3936 6.02272 12.3553C6.05969 12.3161 6.08908 12.2548 6.08908 12.1767V9.29431C6.08908 9.21618 6.05969 9.15485 6.02272 9.11571C5.98649 9.07736 5.94914 9.0649 5.91686 9.0649H5.33353ZM13.9777 9.11571C13.9407 9.15485 13.9113 9.21618 13.9113 9.29431V12.1767C13.9113 12.2548 13.9407 12.3161 13.9777 12.3553C14.0139 12.3936 14.0512 12.4061 14.0835 12.4061H14.6669C15.4712 12.4061 16.2002 11.7018 16.2002 10.7355C16.2002 9.76915 15.4712 9.0649 14.6669 9.0649H14.0835C14.0512 9.0649 14.0139 9.07736 13.9777 9.11571Z"
                fill="#E7131A"
              />
            </svg>
            {expertAdvice.liveChat.text}
          </button>
        </div>
      </div>
    </section>
  )
}

export default WhyShopAtGuitarCenter
