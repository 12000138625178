import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

const MosaicGrid = ({ items, actionId, title }) => {
  return (
    <div
      data-monetate-action-id={actionId}
      data-monetate-component-name={'MosaicGrid'}
      className="grid grid-cols-2 md:grid-cols-4 gap-5 legacy:gap-6 max-w-[1492px] justify-center p-5 legacy:px-0 md:mx-auto"
    >
      {title && (
        <h3 className="col-span-full h4 md:h3 text-center text-textDarkColor font-bold py-2 legacy:p-4">
          {title}
        </h3>
      )}
      {/* Col 1 */}
      <div className="flex flex-col gap-5 legacy:gap-6 col-span-2 md:col-span-1">
        {/* Tile 1 */}
        <Link
          href={items[0].href}
          className="relative overflow-hidden flex text-white h-0 pt-[150.13%]"
        >
          <Image
            src={items[0].imageSrc}
            alt={items[0].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
        {/* Tile 2 */}
        <Link
          href={items[1].href}
          className="relative overflow-hidden justify-end flex flex-col text-white h-0 pb-[66.49%]"
        >
          <Image
            src={items[1].imageSrc}
            alt={items[1].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
      </div>
      {/* Col 2 */}
      <div className="grid grid-cols-2 col-span-2 gap-5 legacy:gap-6">
        {/* Tile 3 */}
        <Link
          href={items[2].href}
          className="basis-1/2 relative overflow-hidden flex flex-col text-white px-5 h-0 pb-[100%] justify-center"
        >
          <Image
            src={items[2].imageSrc}
            alt={items[2].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
        {/* Tile 4 */}
        <Link
          href={items[3].href}
          className="basis-1/2 relative overflow-hidden flex text-white h-0 pb-[100%]"
        >
          <Image
            src={items[3].imageSrc}
            alt={items[3].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
        {/* Tile 5 */}
        <Link
          href={items[4].href}
          className="col-span-2 relative overflow-hidden flex text-white h-0 pb-[55.23%] lg:pb-[56.43%]"
        >
          <Image
            src={items[4].imageSrc}
            alt={items[4].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
      </div>
      {/* Col 3 */}
      <div className="flex flex-col gap-5 legacy:gap-6 col-span-2 md:col-span-1">
        {/* Tile 6 */}
        <Link
          href={items[5].href}
          className="relative overflow-hidden flex text-white h-0 pb-[66.49%]"
        >
          <Image
            src={items[5].imageSrc}
            alt={items[5].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
        {/* Tile 7 */}
        <Link
          href={items[6].href}
          className="relative overflow-hidden justify-end flex flex-col text-white h-0 pt-[150.13%]"
        >
          <Image
            src={items[6].imageSrc}
            alt={items[6].altText}
            fill
            style={{ objectFit: 'cover' }}
            className="z-0"
          />
        </Link>
      </div>
    </div>
  )
}

export default MosaicGrid
