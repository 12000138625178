import { QUERY_KEY_PDP_PAGE_INFO } from '@constants/query/pdp'
import { getCartInfo } from '@gc/common-lib/api/cart'
import type { IPDPPageInfo } from '@gc/common-lib/api/page-data'
import { getPDPPageInfoByAPI } from '@gc/common-lib/api/page-data'
import { GC_CART_PAGE_INFO_QUERY_KEY } from '@gc/common-lib/constants/query/cart'
import { isBrowser } from '@gc/common-lib/utils/browser'
import { save } from '@store/cart'
import type { RootState } from '@store/index'
import { store } from '@store/index'
import { useQuery } from '@tanstack/react-query'
import { get, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

export const usePDPPageInfo = (): IPDPPageInfo => {
  const reduxSkuId = useSelector((state: RootState) => state.pdp.skuId)
  const skuId =
    reduxSkuId ||
    ((isBrowser() &&
      get(window, 'GCI.digitalData.pageData.contentKey', '')) as string)

  const { data } = useQuery({
    queryKey: [QUERY_KEY_PDP_PAGE_INFO, skuId],
    queryFn: () => getPDPPageInfoByAPI({ skuId, segment: '' }),
    enabled: !!skuId
  })

  return data || ({} as IPDPPageInfo)
}

const successCallback = data => {
  if (data && !isEmpty(data?.propFromStoreConfig)) {
    const newData = {
      storeConfig: data?.propFromStoreConfig?.value ?? {}
    }
    store.dispatch(save({ cartInitData: newData }))
  }
  if (data.isNewCheckoutFlow) {
    store.dispatch(save({ isNewCheckoutFlow: true }))
  } else {
    store.dispatch(save({ isNewCheckoutFlow: false }))
  }
}

export const useCartPageInfo = () => {
  const { data, isPending } = useQuery({
    queryKey: [GC_CART_PAGE_INFO_QUERY_KEY],
    queryFn: () => getCartInfo(successCallback),
    refetchOnMount: 'always'
  })

  return {
    data,
    isPending
  }
}
