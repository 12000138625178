import SwiperWithNavigationLayout from '@bc/algolia-endeca/template/_SwiperWithNavigationLayout'
import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { SwiperSlide } from '@gc/ui-components/swiper'
import { get } from 'lodash'
import type { SwiperOptions } from 'swiper/types'

import type { SlideType } from '../skeletons/CarouselSectionSkeleton'
import PriceDropsOnTrendingGear from '../slide-templates/PriceDropsOnTrendingGear'
import TopDeals from '../slide-templates/TopDeals'

export type CarouselSection = {
  imageLink: string
  description?: string
  altText: string
  price: number
  salePrice: number
  productIcid: string
} & ({ link: string; href?: never } | { href: string; link?: never })
export type SourceOverride = CarouselSection[] & {
  slide?: SlideType
  viewAllUrl?: string
  data: any
}

type SwiperCarouselSettings = {
  spaceBetween?: number
  freeMode?: boolean
  direction?: 'horizontal' | 'vertical'
  mousewheel?: {
    enabled: boolean
    forceToAxis?: boolean
  }
  scrollbar?: {
    enabled: boolean
    hide: boolean
  }
  a11y?: {
    enabled: boolean
  }
  breakpoints?:
    | { [width: number]: SwiperOptions; [ratio: string]: SwiperOptions }
    | undefined
  pagination?: {
    enabled: boolean
    clickable?: boolean
  }
  buttonContent?: React.ReactNode
  offset?: [string, string]
}

export type HomepageCarouselSectionProps = {
  title?: string
  viewAllUrl?: string
  source: SourceOverride
  slide: SlideType
  carouselSettings?: SwiperCarouselSettings
  actionId: string
  productIcid: string
}

const slides = {
  PriceDropsOnTrendingGear: ({ source }) => (
    <PriceDropsOnTrendingGear {...source} />
  ),
  TopDeals: ({ source }) => <TopDeals {...source} />
}

const HomepageCarouselSection: React.FC<
  HomepageCarouselSectionProps
> = props => {
  const {
    title,
    viewAllUrl,
    source,
    slide,
    carouselSettings,
    actionId,
    productIcid
  } = props
  const SlideComponent = slides[slide as keyof typeof slides]

  if (!SlideComponent) return null

  const defaultBreakpoints = {
    0: {
      slidesPerView: 2
    },
    [GC_BREAK_POINTS_CONFIG.middle]: {
      slidesPerView: 4
    },
    [GC_BREAK_POINTS_CONFIG.legacy]: {
      slidesPerView: 5
    }
  }

  const {
    spaceBetween = 20,
    freeMode = true,
    direction = 'horizontal',
    mousewheel = { enabled: true, forceToAxis: true },
    scrollbar = { enabled: true, hide: true },
    breakpoints = defaultBreakpoints,
    pagination = { enabled: false, clickable: true },
    buttonContent,
    offset = ['8px', '30%']
  } = carouselSettings || {}

  const offsetX = get(offset, 0, '')
  const offsetY = get(offset, 1, '')

  if (!isArrayAndNotEmpty(source)) return null
  return (
    <div
      className="flex flex-col flex-wrap mt-3 mb-0 mx-auto p-5 lg:px-10 max-w-[1520px]"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'HomepageCarouselSection'}
    >
      {(title || viewAllUrl) && (
        <div className="flex justify-between items-center mb-2 pb-[1.5%] w-full overflow-hidden">
          {title && (
            <h3 className="h4 md:h3 text-textDarkColor font-bold">{title}</h3>
          )}
          {viewAllUrl && (
            <Link
              href={viewAllUrl}
              className="font-semibold text-base text-[#E7131A] hover:underline flex items-center whitespace-nowrap"
            >
              View All
              <svg
                className="ml-1.5"
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 3L12 9L6 15"
                  stroke="#E7131A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </div>
      )}

      <div className="relative md:mx-auto mt-8 w-full">
        <SwiperWithNavigationLayout
          swiperConfig={{
            spaceBetween: spaceBetween,
            freeMode: freeMode,
            direction: direction,
            mousewheel: mousewheel,
            scrollbar: scrollbar.enabled ? { ...scrollbar } : undefined,
            breakpoints: breakpoints,
            pagination: pagination.enabled ? { ...pagination } : undefined,
            style: {
              '--swiper-pagination-color': '#cd2418',
              '--swiper-pagination-bullet-inactive-color': '#999999',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-size': '12px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
              '--swiper-pagination-bottom': '0'
            } as React.CSSProperties
          }}
          hideNavWhenDisabled={false}
          hideNavForMobile={false}
          swiperBtnContent={
            buttonContent || (
              <Image
                src="/assets/images/pdp/gallery-left-navigation.svg"
                alt="next"
                width={50}
                height={50}
                priority={true}
              />
            )
          }
          swiperBtnSize={50}
          btnClassName={`${offsetX ? `mx-[${offsetX}]` : ''} ${offsetY ? `top-[${offsetY}]` : ''}`}
        >
          {source?.map((item, index) => {
            item.productIcid = productIcid
            return (
              <SwiperSlide key={index}>
                <SlideComponent source={item} />
              </SwiperSlide>
            )
          })}
        </SwiperWithNavigationLayout>
      </div>
    </div>
  )
}

export default HomepageCarouselSection
