import { QUERY_KEY_HOMEPAGE_ALGOLIA_PRODUCT_CAROUSEL } from '@constants/query/home'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { logger } from '@gc/common-lib/utils/logger'
import { algoliaClient } from '@helper/utils/algoliaClient'
import { applyDynamicDataIntoQuery } from '@helper/utils/algoliaQuery'
import { usePDPPageInfo } from '@hooks/pdp/pageInfo'
import { useQuery } from '@tanstack/react-query'
import qs from 'qs'

import HomepageCarouselSection from '../carousel-section'
import CarouselSectionSkeleton, {
  getSkeletonClassName
} from '../skeletons/CarouselSectionSkeleton'
import { mapAlgoliaHits } from './server'

export default function ClientAlgoliaProductCarousel({
  source,
  slide,
  title,
  viewAllUrl,
  actionId,
  productIcid
}: any) {
  const pageData = usePDPPageInfo()

  const { data, isPending } = useQuery({
    queryKey: [QUERY_KEY_HOMEPAGE_ALGOLIA_PRODUCT_CAROUSEL, source?.query],
    queryFn: async () => {
      try {
        const cookie = qs.parse(document.cookie, { delimiter: ';' })
        const result = await algoliaClient.search([
          applyDynamicDataIntoQuery(source?.query, { cookie, pageData })
        ])
        return result.results[0]
      } catch (error) {
        logger.error({
          error,
          message: 'algolia-product-carousel request failed'
        })
        return null
      }
    },
    enabled: Boolean(source?.query)
  })

  if (!source) {
    return null
  }

  if (isPending) {
    return (
      <CarouselSectionSkeleton
        className={getSkeletonClassName(slide)}
        title={title}
        noClientData={source?.noClientData}
        viewAllUrl={viewAllUrl}
        cardsToShow={{
          small: 2,
          middle: 3,
          large: 4,
          xl: 5
        }}
      />
    )
  }

  if (isArrayAndNotEmpty((data as any)?.hits)) {
    return (
      <HomepageCarouselSection
        title={title}
        source={mapAlgoliaHits((data as any).hits)}
        slide={slide}
        viewAllUrl={viewAllUrl}
        actionId={actionId}
        productIcid={productIcid}
      />
    )
  }

  return null
}
