import { get, reduce } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const applyDynamicDataIntoQuery = (query: any, source: any) => ({
  ...query,
  params: {
    ...query.params,
    facetFilters: query.params.facetFilters.map((filter: string) => {
      const matched = /([^:]+):.*({[^}]+})/.exec(filter)

      if (matched) {
        const attribute = matched[1]
        const matchedValue = filter.match(/(?<={)([^}]+)(?=})+/g)

        if (matchedValue) {
          return reduce(
            matchedValue,
            (acc, current) => {
              const value = get(source, current)
              if (value) acc.push(`${attribute}:${value}`)
              return acc
            },
            [] as Array<string>
          )
        }

        return []
      }

      return filter
    })
  }
})
