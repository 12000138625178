import {
  forwardCookieFields,
  monetateApiBaseUrl,
  monetateChannel
} from '@constants/monetate'
import { thirdPartyService } from '@gc/common-lib/api/request'
import { readCookie } from '@gc/common-lib/utils/browser'
import { logger } from '@gc/common-lib/utils/logger'
import { flatMap } from 'lodash'

type ClientRequestMonetateActionsParams = {
  pageType: string
  pageEvents?: any[]
  customVariables?: { variable: string; value: string }[]
}

export const requestMonetateActionsClientSide = ({
  pageType,
  pageEvents = []
}: ClientRequestMonetateActionsParams): Promise<any> => {
  const monetateReqId = Date.now().toString()

  const events = [
    {
      eventType: 'monetate:context:CustomVariables',
      customVariables: [
        {
          variable: 'serverSide',
          value: 'false'
        }
      ]
    },
    {
      eventType: 'monetate:decision:DecisionRequest',
      requestId: monetateReqId,
      includeReporting: false
    },
    {
      eventType: 'monetate:context:PageView',
      url: window.location.href,
      pageType: pageType
    },
    {
      eventType: 'monetate:context:UserAgent',
      userAgent: navigator.userAgent
    },
    ...pageEvents
  ]

  const monetateId = document.cookie
    .split('; ')
    .find(row => row.startsWith('mt.v='))
    ?.split('=')[1]

  const referer = document.referrer

  if (referer) {
    events.push({
      eventType: 'monetate:context:Referrer',
      referrer: referer
    })
  }
  forwardCookieFields.forEach(field => {
    const cookieValue = readCookie(field)
    if (cookieValue) {
      events[0].customVariables.push({
        variable: field,
        value: cookieValue
      })
    }
  })
  return thirdPartyService
    .post(
      monetateChannel,
      { channel: monetateChannel, events, monetateId },
      {
        baseURL: monetateApiBaseUrl
      }
    )
    .then(res => {
      const actions = flatMap(res.data.data.responses, 'actions')

      return actions
        .map(item => {
          if (item.json) {
            return { ...item.json, actionId: item.actionId } // Keep actions with a json field
          } else if (item.items) {
            return {
              actionId: item.actionId,
              actionType: item.actionType,
              component: item.component,
              items: item.items // Keep recommendation engine items
            }
          }
          return null // Filter out actions without json or items
        })
        .filter(Boolean) // Remove null values
    })
    .catch(error => {
      logger.error({
        message: 'client request monetate actions error---->',
        error,
        events,
        monetateId,
        monetateChannel,
        monetateApiBaseUrl
      })
      return []
    })
}

export const applyClientSideActions = (layout, actions) => {
  const updatedLayout = { ...layout }
  const applyActions = component => {
    const type = component['@type']

    component.clientSideDataFetched = true

    if (!component.source) {
      const action = actions.find(
        a => a.selector === type || a.component === type
      )

      if (action) {
        if (action.items) {
          component.source = action.items
        } else if (action.source) {
          component.source = action.source
        }

        component.actionId = action.actionId
      } else {
        component.noClientData = true
      }
    }

    if (component.children) {
      component.children = component.children.map(applyActions)
    }

    return component
  }

  updatedLayout.children = updatedLayout.children.map(applyActions)

  return updatedLayout
}
