import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'

type Service = {
  href: string
  imgSrc: string
  altText: string
  title: string
  description: string
  linkText: string
  arrowImgSrc: string
}

type ServicesProps = { source: Service[]; actionId: string }

const ServiceCard: React.FC<Service> = ({
  href,
  imgSrc,
  altText,
  title,
  description,
  linkText,
  arrowImgSrc
}) => {
  return (
    <a
      className="flex flex-col flex-1 border border-[#bbbbbb] rounded h-auto group/item"
      href={href}
      aria-label={altText}
    >
      <Image
        src={specifyImageSize(imgSrc, 400)}
        alt={altText}
        width={400}
        height={300}
        className="object-cover w-full"
      />

      <div className="text-[#333] p-4 flex-1 flex flex-col justify-between">
        <div>
          <h5 className="text-xl font-bold text-[#333] mt-2.5">{title}</h5>
          <p className="text-base font-normal mt-2 group-hover:text-[#333]">
            {description}
          </p>
        </div>
        <button className="font-semibold text-[#E7131A] text-base flex items-center mt-6 self-baseline mb-2">
          {linkText}
          <Image
            src={arrowImgSrc}
            alt=""
            width={9}
            height={16}
            className="ms-2.5"
          />
        </button>
      </div>
    </a>
  )
}

const Services: React.FC<ServicesProps> = ({ source: services, actionId }) => {
  return (
    <ul
      className="flex flex-row gap-4 max-w-[1492px] mx-auto p-5 flex-wrap legacy:!flex-nowrap"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'Services'}
    >
      {services?.map((service, index) => (
        <li className="flex flex-col basis-full legacy:basis-1/3" key={index}>
          <ServiceCard {...service} />
        </li>
      ))}
    </ul>
  )
}

export default Services
