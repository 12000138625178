import ProductCondition from '@bc/algolia-plp/product-grid/product-condition'
import { formatCurrency } from '@gc/common-lib/utils/currency'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { isEmpty } from 'lodash'

type Deal = {
  imageLink: string
  description?: string
  altText: string
  price: number
  salePrice: number
  title?: string
  productIcid?: string
  usedCondition?: string
  openBoxStyleOptions?: any
  storeName?: string
} & ({ link: string; href?: never } | { href: string; link?: never })

const TopDeals: React.FC<Deal> = ({
  imageLink,
  description,
  href,
  link,
  altText,
  price,
  salePrice,
  storeName,
  title,
  usedCondition,
  openBoxStyleOptions,
  productIcid
}) => (
  <>
    <Link
      className="flex flex-row flex-wrap justify-start px-3.5 pb-10"
      href={
        productIcid
          ? `${href || link}?icid=${productIcid}`
          : ((href || link) ?? '/')
      }
      aria-label={altText}
    >
      <Image
        src={specifyImageSize(imageLink, 180)}
        alt={altText}
        height={180}
        width={180}
        className="basis-full w-full h-auto mx-auto max-w-[180px] transition-opacity duration-500 ease-in-out opacity-100"
      />
      <div className="basis-full mb-1 mt-4">
        {salePrice && price && salePrice !== price && (
          <div className="py-2 px-2.5 bg-[#e91b23] text-white font-normal text-xs flex w-fit">
            <div className="font-semibold me-1.5">Save:</div>{' '}
            {formatCurrency(price - salePrice, '$')}
          </div>
        )}
      </div>
      {title && <p className="text-black text-sm mt-4">{title}</p>}
      <p className="text-textDarkColor font-semibold mb-2.5 basis-full">
        {description}
      </p>
      {salePrice && price && salePrice === price && (
        <p className="text-[#E7131A] font-bold me-3 text-base">
          {formatCurrency(salePrice, '$')}
        </p>
      )}
      {salePrice && price && salePrice !== price && (
        <>
          <p className="text-[#E7131A] font-bold me-3 text-base">
            {formatCurrency(salePrice, '$')}
          </p>
          <p className="text-gray-500 line-through text-base">
            {formatCurrency(price, '$')}
          </p>
        </>
      )}
      {!salePrice && price && (
        <p className="text-[#E7131A] font-bold me-3 text-base">
          {formatCurrency(price, '$')}
        </p>
      )}
      {salePrice && !price && (
        <p className="text-[#E7131A] font-bold me-3 text-base">
          {formatCurrency(salePrice, '$')}
        </p>
      )}
      {storeName && (
        <div className="font-normal mb-2 text-xs flex flex-wrap items-center w-full mt-2 gap-x-2">
          <Image
            src="/assets/images/pdp/home.svg"
            width={20}
            height={18}
            alt="find in store icon"
          />
          <div className="flex flex-col gap-x-1">
            <span className="text-[#058000] whitespace-nowrap">
              Available at:
            </span>
            <div className="store-name-text text-[#2d2d2d] hover:text-[#2d2d2d]">
              {storeName}
            </div>
          </div>
        </div>
      )}
      {usedCondition === 'Used' && (
        <p className="font-normal mb-2 text-xs text-[#2d2d2d] hover:text-[#2d2d2d]">
          Condition: {usedCondition.replace('Condition', '')}
        </p>
      )}
    </Link>
    {!isEmpty(openBoxStyleOptions) && (
      <ProductCondition
        className="-translate-y-10 ps-3.5"
        openBoxStyleOptions={openBoxStyleOptions}
      />
    )}
  </>
)

export default TopDeals
