import ProductSticker from '@bc/global/product-sticker'
import { formatCurrency } from '@gc/common-lib/utils/currency'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import type { FC } from 'react'

interface PriceDropsOnTrendingGearProps {
  altText: string
  financing?: string
  link?: string
  href?: string
  price?: string | number
  salePrice?: string | number
  title: string
  imageLink: string
  productIcid?: string
  stickers?: any
}

const PriceDropsOnTrendingGear: FC<PriceDropsOnTrendingGearProps> = ({
  altText,
  financing,
  link,
  href,
  price,
  salePrice,
  title,
  imageLink,
  productIcid,
  stickers
}) => (
  <Link
    className="flex flex-col font-normal justify-start items-start h-full px-3.5 pb-10 group"
    href={
      productIcid
        ? `${href || link}?icid=${productIcid}`
        : ((href || link) ?? '/')
    }
    aria-label={altText}
  >
    <div className="mx-auto relative overflow-hidden">
      <Image
        src={specifyImageSize(imageLink, 160)}
        alt={altText}
        height={160}
        width={160}
        className="mx-auto"
      />
    </div>
    <p className="text-[#161616] font-semibold text-base mt-2 line-clamp-2">
      {title}
    </p>
    <div className="flex flex-wrap items-center mt-2">
      {salePrice && price && salePrice === price && (
        <p className="me-3 text-base gap-x-1 font-bold text-[#E7131A]">
          {formatCurrency(salePrice, '$')}
        </p>
      )}
      {salePrice && price && salePrice !== price && (
        <>
          <p className="me-3 text-base gap-x-1 font-bold text-[#E7131A]">
            {formatCurrency(salePrice, '$')}
          </p>
          <p className="line-through text-[#676767] text-base">
            {formatCurrency(price, '$')}
          </p>
        </>
      )}
      {!salePrice && price && (
        <p className="me-3 text-base gap-x-1 font-bold text-[#E7131A]">
          {formatCurrency(price, '$')}
        </p>
      )}
      {salePrice && !price && (
        <p className="me-3 text-base gap-x-1 font-bold text-[#E7131A]">
          {formatCurrency(salePrice, '$')}
        </p>
      )}
    </div>
    {stickers && stickers.length > 0 && (
      <div className="mt-2 flex gap-2">
        {stickers.map((stickerProps, index) => (
          <ProductSticker key={index} {...stickerProps} />
        ))}
      </div>
    )}
    {financing && (
      <p className="text-[#161616] text-sm font-semibold bg-[#F9F9F9] mb-2 border-l-[.188rem] border-[#DDDDDD] p-[.188rem] ps-3 pe-1 mt-3 w-full">
        {financing}
      </p>
    )}
  </Link>
)

export default PriceDropsOnTrendingGear
