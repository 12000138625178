import type { CartTypings } from '../../typings/cart'
import { isArrayAndNotEmpty } from '../../utils/lodash'
import { setGCIDigitalData } from '../../utils/window'
import ATGService, { thirdPartyService } from '../request'

export interface ICartItem {
  catalogRefId: string
  productId?: string
  quantity: number | string
  lessonSkuId?: string
  lessonStoreId?: string
  commerceType?: string
  warrantyBaseItem?: string
  warrantyBaseCommerceItemId?: string
  deliveryPromisedDate?: number
  deliveryPromiseMessage?: string
  storeOnly?: boolean
}

export interface IAddToCartOBJ {
  addItemCount: number | string
  items: ICartItem[]
  excludeOmnitureData?: boolean
  warrantyCatalogRefIdSPA?: string
  deliveryZipCode?: string
  warrantyBaseItem?: string
  warrantyBaseCommerceItemId?: string
  type: string
  storeOnly?: boolean
}
export const addItemToOrder = (params: IAddToCartOBJ): Promise<any> => {
  return ATGService({
    url: '/atg/checkout/checkoutActor/addItemToOrder',
    method: 'post',
    needSession: true,
    data: { ...params },
    needLoading: true
  })
}

export const getCartInfo = async (
  callback?: any
): Promise<CartTypings.ICartPageInfoProps> => {
  const saveForLaterPage =
    sessionStorage.getItem('CartSaveForLaterListPage') ?? 1
  const res = await ATGService({
    url: `/atg/commerce/ShoppingCartActor/cartRedesignNew?saveForLaterPage=${saveForLaterPage}`
  })

  // for cart page omniture track
  if (res?.data?.analyticsData) {
    setGCIDigitalData('omnitureData', res.data.analyticsData)
    ;(window as any).dataLayer = (window as any).dataLayer || []
    ;(window as any).dataLayer.push({
      eventName: 'omnitureLoaded',
      omnitureLoaded: {
        pageLocation: 'cart',
        omnitureData: res.data.analyticsData
      }
    })

    // 1;1274115033933;127474186670288006;site5avd;guitars;
    // map as below:
    // quantity ; skuId ; productId ; categoryId ; category name
    const cartAddition = res?.data?.analyticsData?.cartAddition
    if (cartAddition) {
      const cartAdditionArr = cartAddition.split(';')
      if (isArrayAndNotEmpty(cartAdditionArr)) {
        const [quantity, skuId, productId, categoryId, categoryName] =
          cartAdditionArr
        ;(window as any).dataLayer.push({
          eventName: 'detectedNewItemInCart',
          detectedNewItemInCart: {
            pageLocation: 'cart',
            quantity,
            skuId,
            productId,
            categoryId,
            categoryName
          }
        })
      }
    }
  }

  callback?.(res.data)

  return res?.data?.order
}

export async function removeCartItem(params: {
  removalCommerceIds: string[]
  requestType?: string
  fromPage?: string
}) {
  const { requestType, fromPage, ...restParams } = params
  return ATGService({
    url: `/atg/checkout/checkoutActor/removeItemFromOrder?SPA=true`,
    method: 'post',
    data: {
      ...restParams,
      fromPage: fromPage || 'cart',
      type: requestType || 'redesign_cart'
    },
    needSession: true,
    needLoading: true
  })
}
export const loadMoreSaveForLaterList = async currentPage => {
  const res = await ATGService({
    url: '/ngp/commerce/gifts/SaveForLaterActor/saveForLaterList',
    method: 'get',
    params: {
      page: String(currentPage)
    },
    needLoading: true
  })
  return res?.data
}

export const saveForLater = async data => {
  const res = await ATGService({
    url: '/ngp/commerce/gifts/SaveForLaterActor/saveForLater?SPA=true',
    method: 'post',
    data: { ...data, priority: 1, type: 'redesign_cart', sfl: true },
    needSession: true,
    needLoading: true,
    showSessionTimeOutMessage: false
  })

  return res?.data
}

export const moveSaveForLaterItemToCart = async data => {
  const currentPage = sessionStorage.getItem('CartSaveForLaterListPage') ?? 1
  const res = await ATGService({
    url: '/ngp/commerce/gifts/SaveForLaterActor/moveItemToOrder?SPA=true',
    method: 'post',
    data: {
      ...data,
      priority: 1,
      type: 'redesign_cart',
      sfl: true,
      saveForLaterPage: Number(currentPage)
    },
    needSession: true,
    needLoading: true
  })

  return res?.data
}

export const removeSaveForLaterItem = async params => {
  const currentPage = sessionStorage.getItem('CartSaveForLaterListPage') ?? 1
  const res = await ATGService({
    url: `/atg/userprofiling/ProfileActor/removeItemFromGiftlist?SPA=true`,
    method: 'post',
    data: {
      ...params,
      type: 'redesign_cart',
      sfl: true,
      page: Number(currentPage)
    },
    needSession: true,
    needLoading: true
  })

  return res?.data
}

export async function removeCartQuoteItem(quoteCode) {
  return ATGService({
    url: `/atg/checkout/checkoutActor/removeQuoteFromCart?SPA=true`,
    method: 'post',
    data: { ...quoteCode, type: 'redesign_cart' },
    needSession: true,
    needLoading: true
  })
}

export async function removeCartFreeGiftItem(freeGiftCode) {
  return ATGService({
    url: `/atg/checkout/checkoutActor/removeChildItem`,
    method: 'post',
    data: { ...freeGiftCode, type: 'redesign_cart' },
    needSession: true,
    needLoading: true
  })
}

export async function updateCartQty(params: {
  commerceId: string
  quantity: number
  warrantyId?: string
  modifyOrderPricingOp?: string
  requestType?: string
  fromPage?: string
}) {
  const {
    commerceId,
    quantity,
    warrantyId = '',
    modifyOrderPricingOp = 'ORDER_SUBTOTAL_SHIPPING',
    requestType = 'redesign_cart',
    fromPage = 'cart'
  } = params
  const warrantyKeyVal = warrantyId ? { [warrantyId]: quantity } : undefined

  return ATGService({
    url: `/atg/checkout/checkoutActor/setOrderByCommerceId`,
    method: 'post',
    data: {
      [commerceId]: quantity,
      ...warrantyKeyVal,
      modifyOrderPricingOp,
      type: requestType,
      fromPage,
      SPA: true
    },
    needSession: true,
    needLoading: true
  })
}

interface IApplyShipTo {
  postalCode: string
  shipState: string
}

interface ICartShipToMethodItem {
  label: string
  shipMethod: string
  shipping: string
  tax: string
  estimatedArrivalDate: string
  message: string
}
interface ICartShipToErrorItem {
  field: string
  value: string
}
interface IApplyGCCartShipTo {
  items: {
    type: string
    methods?: ICartShipToMethodItem[]
    errors?: ICartShipToErrorItem[]
  }[]
}

export async function applyGCCartShipTo(
  params: IApplyShipTo
): Promise<IApplyGCCartShipTo> {
  const res = await thirdPartyService({
    url: `/responsive/includes/cart/shippingTaxEstimate.jsp?zipCode=${params.postalCode}&state=${params.shipState}`,
    method: 'post'
  })
  return res?.data
}

export async function cartClickToCheckout(params) {
  const res = await ATGService({
    url: `/atg/checkout/checkoutActor/moveToPurchaseInfoByRelId?SPA=true`,
    method: 'post',
    data: { ...params },
    needSession: true,
    needLoading: true,
    needSPAFlag: false,
    clearSession: true
  })

  return res?.data
}

export async function cartClickToPaypal(params, needSPAFlag = false) {
  const res = await ATGService({
    url: `/atg/checkout/checkoutActor/paymentWithPaypal`,
    method: 'post',
    data: { ...params },
    needSession: true,
    needLoading: true,
    needSPAFlag // after checkout, need remove this
  })

  return res?.data
}
