import SwiperWithNavigationLayout from '@bc/algolia-endeca/template/_SwiperWithNavigationLayout'
import Image from '@gc/ui-components/image'
import { SwiperSlide } from '@gc/ui-components/swiper'

import PromoProductCard from '../slide-templates/PromoProductCard'

const PromoSlider = ({ title, products, actionId }) => {
  const slidesPerGroup = 3
  const totalSlides = products?.data?.length || 0
  const remainder = totalSlides % slidesPerGroup

  // Set loopAddBlankSlides and loopAdditionalSlides based on the calculation
  const loopAddBlankSlides = remainder !== 0 // true if not divisible by 3
  const loopAdditionalSlides = loopAddBlankSlides ? remainder : 0

  return (
    <div
      className="max-w-[1492px] mx-auto px-5"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'PromoSlider'}
    >
      {title && <p className="text-2xl font-bold mb-4">{title}</p>}
      <SwiperWithNavigationLayout
        swiperConfig={{
          className: 'mb-4',
          spaceBetween: 15,
          loop: true,
          slidesPerGroup: 1,
          loopAddBlankSlides: loopAddBlankSlides,
          loopAdditionalSlides: loopAdditionalSlides,
          breakpoints: {
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1
            },
            320: {
              slidesPerView: 1.25
            },
            768: {
              slidesPerView: 1.5
            },
            976: {
              slidesPerGroup: slidesPerGroup,
              slidesPerView: 3.15
            }
          }
        }}
        swiperBtnContent={
          <Image
            src="/assets/icons/homepage/swiper-arrow.svg"
            alt="next"
            width={36}
            height={36}
            priority={true}
            className="max-w-none"
          />
        }
        hideNavForMobile={false}
        btnClassName="top-[30%] px-6"
        swiperContainerClassName=""
      >
        {products?.data?.map((product, i) => (
          <SwiperSlide key={i}>
            <PromoProductCard key={product.id} {...product} />
          </SwiperSlide>
        ))}
      </SwiperWithNavigationLayout>
    </div>
  )
}

export default PromoSlider
