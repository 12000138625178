import type { RichRelevanceTypings } from '../../typings/rich-relevance'
import type { VideoTypings } from '../../typings/video'
import { serverRequest } from '../../utils/fetch'
import { logger } from '../../utils/logger'
import ATGService from '../request'
/**
 * ==========================typings==========================
 */

export interface IPriceConditionProps {
  salePrice?: number
  wasPrice?: number
  isOnSale?: boolean
  className?: string
  subscriptionDuration?: string
}
export interface IPDPRightPriceProps extends IPriceConditionProps {
  priceType?:
    | ''
    | 'Clearance'
    | 'Platinum'
    | 'Vintage'
    | 'Used'
    | 'Open Box'
    | 'New'
  /* priceTypeCondition: price condition content */
  priceTypeCondition?: string
  /* priceTypeTooltipContent: price type tooltip content */
  priceTypeTooltipContent?: string
  priceTitle?: string
  /* deadlineTime: on-sale deadline */
  deadlineTime?: string
  useBundleSavePrice?: number
  unavailableProduct?: boolean
}
export interface IPDPDetailProps {
  overallRating: number
  alertType: string
  productId: string
  featuresDetails: string
  sticker: string | any[]
  brandImgSrc: string
  brandImgLink: string
  brandName: string
  postId: string
  title: string
  productName: string
  ratingCount: string
  catalogRefId: string
  features: string
  siteId: string
  id: string
  commerceType: string
  defaultLessonsStoreId: string
  categoryId: string
  showRatingDesc: boolean
  ratingDescArr: number[]
  expertTotalReviews?: string
  skuUrl: string
  upcCode: string
  stickerLink?: string
  stickerLinkTarget?: boolean
  isSubscription?: boolean
  unavailablePDP?: boolean
  startDate?: number
}
export interface IACCSimpleCart {
  total: string
  count: string
  itemCount: number
}
export interface IFinancing {
  easyPayOptId?: string
  secondaryOptId?: string
  showEasyPay?: boolean
  easyPayAmt?: number | string
  easyPayMonths?: number
  financingTextContent?: string
  financingLinkText?: string
  financingLink?: string
  showLease?: boolean
  showEstimate?: boolean
}
export interface IProductImage {
  imageId?: string
  imageJsonp?: string
  smallImageSrc: string
  middleImageSrc: string
  largeImageSrc: string
}
export interface IAddToCartConfirmationPageInfo {
  productImages: IProductImage
  PDPBundle: RichRelevanceTypings.IRichRelevanceProps
  PDPRightPrice: IPDPRightPriceProps
  PDPDetail: IPDPDetailProps
  RichRelevanceProduct: RichRelevanceTypings.IRichRelevanceKey
  ACCSimpleCart: IACCSimpleCart
  ACCGearCard: IFinancing
}
export interface IPDPPropertySelector {
  propertyHTML: string
}
export interface IPDPFamilyLinks {
  [key: string]: {
    id: string
    name: string
    url: string
  }[]
}
export interface IBreadcrumb {
  seoUrl: string
  displayName: string
}
export interface IPDPSeeAllProductProps {
  title: string
  link: string
}
interface IArticle {
  id: string
  imgSrc: string
  title: string
  link: string
}
export interface IPDPArticleProps {
  relatedLink: string
  articles: IArticle[]
  articleSectionTitle: string
  relatedText: string
}
export interface IVideoProps {
  videoSrc: string
  type: VideoTypings.VideoType
  description: string
  imageSrc: string
  title: string
  metadata?: any
}
export interface IPDPLocatedProps {
  located?: string
  title: string
  phone: string
  emailUrl: string
  link?: string
}
export interface IPDPPromoIconsProps {
  className: string
  linkUrl?: string
  iconUrl?: string
  linkText: string
}
export interface ICheckItemProps {
  text: string
  id: string
  checked: boolean
  salePrice: number
  wasPrice?: number
}
export interface IPDPProtectionItemProps {
  imgSrc: string
  title: string
  subTitle?: string
  promoMessage?: string
  moreTitle: string
  type: 'lesson' | 'proCoverage'
  checkArray: ICheckItemProps[]
}
interface IPDPStyleSelectorArrProps {
  id: string
  link: string
  imgSrc?: string
  salePrice: string
  isSelected: boolean
  styleDescription: string
  status?: string
  wasPrice?: string
  className?: string
  skuCondition: string
  availableDate: Date | number
}
export interface IPDPStyleSelectorProps {
  type: 'modal' | 'swiper' | 'label' | ''
  selectedStyle: string
  showPrice: boolean
  styleSelectorArr: IPDPStyleSelectorArrProps[]
}
export interface ISubConditionArrProps {
  salePrice: number
  styleDescription?: string
  typeName: string
  typeTooltip?: string
  skuId: string
  productId: string
}
export interface IConditionArrProps {
  typeCondition: ISubConditionArrProps[]
  conditionName: string
}
interface IConditionListProps {
  condition: IConditionArrProps[]
  conditionPrice: number
  conditionSummary: string
}
export interface IPDPGiftAndDownloadProps {
  imgSrc: string
  type: string
  price: number
  title?: string
  link?: string
  description?: string
  expireDate: string
  rebateLink?: string
  displayId?: string
}
export type CTAButtonType =
  | 'primary'
  | 'primary_ghost'
  | 'phone'
  | 'chat'
  | 'disabled'
  | 'segmentation'
export interface ICTAButton {
  type: CTAButtonType
  quantity: boolean
  text: string
}
export interface IPDPCTAProps {
  CTAButtons: ICTAButton[]
  inventoryStatus?: string
  invDetailMessage?: string
  emailTextTo?: string
  emailTextBack?: string
  availableDate?: number
  soimTitle?: string
  soimDescription?: string
  storeSkuChoose?: boolean
  storeSkuChooseValue?: string
  availableDelivery?: boolean
}
export interface IPDPHelpProps {
  iconUrl?: string
  mainText: string
  tag?: string
  phoneNumber: string
  showLiveChat?: boolean
}
interface ILink {
  text: string
  href: string
}
export interface IFooterLinksProps {
  title: string
  links: ILink[]
}
export interface IFooterProps {
  footerLegal: string
  footerLinkConfig: IFooterLinksProps[]
  bbbLinksHTML?: string
  phoneNumber: string
  spaPhoneNumber: string
  mobileConnectLinks?: string
}
export interface IPDPNewOrOldModelProps {
  imgSrc: string
  itemVersion: 'newer' | 'older'
  title: string
  srcLink: string
  salePrice?: number
  wasPrice?: number
  rating?: number
  ratingCount?: number
  inCheckoutPromo?: boolean
}
export interface IPDPDescriptionProps {
  description?: string
  features?: string
  specifications?: string
  proCoverage?: string
  prop65?: string
}
interface IDigitalDownloadProps {
  downloadTitle: string
  returnable: string
  inStoreOnly: boolean
  oversized: boolean
  discontinued: boolean
  twoDayShippingSourceStatus: string
  shipToStore: boolean
  availableSelectStore?: boolean
}
export interface IPDPReturnPolicyLinkProps {
  title: string
  link: string
}
interface IPayIn4Config {
  payIn4Enabled: boolean
  payIn4ClientId: string
  payIn4Eligible: boolean
}

interface IOmnitureTagging {
  siteSection: string
  reportSuiteIds: string
  pageType: string
  subCategory2: string
  subCategory1: string
  category: string
  pageName: string
  products: string
}

interface IPDPMetaDataProps {
  description?: string
  title?: string
  canonical?: string
}

export interface ISerializedItems {
  serialNumber: string
  isSelected: boolean
  imgSrc: string
  weight: string
  link: string
  id: string
  imageId: string
}

export interface IPDPSerializedItemProps {
  headingMsg: string
  headingBodyMsg: string
  tailBodyPrefixMsg?: string
  tailBodyLinkMsg?: string
  parentLink?: string
  serialItems: ISerializedItems[]
  serialEmailRecipients?: string[]
  serialPhoneNumber: string
}

export type IShippingDetails = { '@id': '#shipping_policy' }

export type IHasMerchantReturnPolicy = { '@id': '#shipping_policy' }

export interface IOffers {
  '@type': string
  priceCurrency: 'USD'
  price: string
  url: string
  shippingDetails: IShippingDetails
  hasMerchantReturnPolicy: IHasMerchantReturnPolicy
  itemCondition: string
  availability: string
}

export interface IAggregateRating {
  ratingValue: number
  reviewCount: number
}

export type IVariesByDistributedCondition<T extends string> = T extends any
  ? { [k in T]: string }
  : never

export type IVariesByCondition = IVariesByDistributedCondition<
  'color' | 'size' | 'pattern' | 'material'
>

export type IHasVariantBaseType = {
  '@context': 'http://schema.org'
  '@type': 'Product'
  sku: string
  gtin: string
  name: string
  image: string
  aggregateRating: IAggregateRating
  offers: IOffers
} & IVariesByCondition

export type IHasVariant = [IHasVariantBaseType, { url: string }[]]

export interface IPDPVariantInfo {
  variesBy?: string[]
  hasVariant?: IHasVariant
}

export interface IPDPPageInfo {
  PDPMetaData?: IPDPMetaDataProps
  PDPRightPrice: IPDPRightPriceProps
  Breadcrumb: IBreadcrumb[]
  PDPSeeAllProduct: IPDPSeeAllProductProps[]
  PDPArticle: IPDPArticleProps
  productVideos: IVideoProps[]
  PDPUsedLocation: IPDPLocatedProps
  PDPPromoIcons: IPDPPromoIconsProps[]
  PDPProtection: IPDPProtectionItemProps[]
  PDPStyleSelector: IPDPStyleSelectorProps
  RichRelevanceProduct: RichRelevanceTypings.IRichRelevanceKey
  PDPReview: any
  conditionList: IConditionListProps
  productImages: IProductImage
  PDPGiftAndDownload: IPDPGiftAndDownloadProps[]
  PDPCTA: IPDPCTAProps
  Financing: IFinancing
  PDPBundle: RichRelevanceTypings.IRichRelevanceProps
  PDPHelp: IPDPHelpProps
  Footer: IFooterProps
  PDPDetail: IPDPDetailProps
  PDPPropertySelector: IPDPPropertySelector
  PDPFamilyLinks: IPDPFamilyLinks[]
  PDPNewOrOldModel: IPDPNewOrOldModelProps[]
  PDPDescription: IPDPDescriptionProps
  StoreAndDelivery: IDigitalDownloadProps
  PDPReturnPolicyLink: IPDPReturnPolicyLinkProps
  ProductLDJSON: any
  payIn4Config?: IPayIn4Config
  OmnitureTagging: IOmnitureTagging
  PDPSerializedItem?: IPDPSerializedItemProps
  PDPVariantInfo: IPDPVariantInfo
}

export type GetPDPPageInfoParams =
  | { skuId: string; segment?: string; international?: boolean }
  | { entSkuId: string }

export type getPDPPageInfoType = (
  params: GetPDPPageInfoParams
) => Promise<{ code: string; data: IPDPPageInfo; message: string }>

/**
 * ============================api============================
 */
export const getPDPPageInfo: getPDPPageInfoType = async params => {
  let reqParams: GetPDPPageInfoParams | null = null

  if ('skuId' in params) {
    reqParams = {
      skuId: params.skuId,
      segment: params.segment,
      international: false
    }
  } else if ('entSkuId' in params) {
    reqParams = {
      entSkuId: params.entSkuId
    }
  }

  if (!reqParams) {
    return {}
  }

  try {
    const res = await serverRequest.get(
      `/rest/model/ngp/rest/actor/PDPDetailActor/getPDPDetailsCache`,
      { params: reqParams }
    )

    if (res.status !== 200) {
      logger.error({
        message: 'Failed to getPDPPageInfo API request with non 200 status',
        params,
        statusText: res.statusText
      })
    }
    const pageInfo = res?.data
    return pageInfo || {}
  } catch (error) {
    logger.error({
      message: `Failed getPDPPageInfo API request`,
      error,
      params
    })
  }
}
export type getPDPPageInfoByAPIType = (params: {
  skuId: string
  segment?: string
}) => Promise<IPDPPageInfo>

export const getPDPPageInfoByAPI: getPDPPageInfoByAPIType = async ({
  skuId,
  segment
}) => {
  const { data } = await getPDPPageInfo({ skuId, segment })
  return data || ({} as IPDPPageInfo)
}

export const getAddCartConfirmationPageData = async (
  skuId: string
): Promise<any> => {
  const {
    data: { data }
  } = await ATGService({
    url: '/ngp/rest/actor/PDPDetailActor/getCartConfirm',
    params: {
      skuId
    },
    method: 'GET'
  })
  return data
}
/**
 * ============================api============================
 */
