import { IMAGE_PREFIX } from '@constants/media'
import { formatCurrency } from '@gc/common-lib/utils/currency'
import type { AlgoliaPLPTypings } from '@typings/algolia-plp'
import type { PLPTypings } from '@typings/plp'
import dayjs from 'dayjs'
import { find, groupBy, includes, isEmpty, min, uniq } from 'lodash'

interface BaseProductItem {
  bottomStickerClass?: string
  bottomStickerName?: string
  easyPayOptions?: string
  topStickerClass?: string
  topStickerName?: string
  middleStickerClass?: string
  middleStickerName?: string
  price: number
  wasPrice?: number
  skuId: string
  displayName: string
  imageId: string
  linkUrl: string
  thumb: string
  easyPaySkuId: string
  priceVisibility?: number
}

export const openBoxConditions = ['Blemished', 'Open Box', 'Restock']

export const convertEasyPayOptions = (
  easyPayOption?: AlgoliaPLPTypings.EasyPayOption
): string => {
  if (isEmpty(easyPayOption)) {
    return ''
  }

  let easyPayOptionText = ''
  if (dayjs().isAfter(easyPayOption?.startDate)) {
    if (easyPayOption?.months && easyPayOption?.monthlyPayment) {
      easyPayOptionText = `${formatCurrency(easyPayOption?.monthlyPayment, '$')}/mo.‡ with ${easyPayOption.months}-month financing*`
    } else if (easyPayOption?.specialFinancing) {
      easyPayOptionText = `<div class="monthly-payments-details monthly-payments-special-financing">Special Financing Available</div>`
    }
  }

  return easyPayOptionText
}

const checkArraySameAndFindMin = (arr: number[]) => {
  // Get unique values in the array
  const uniqueValues = uniq(arr)

  // Check if all items are the same
  if (uniqueValues.length === 1) {
    return {
      allSame: true,
      minValue: uniqueValues[0] // Since all are the same, minValue is any item
    }
  }
  // Find the minimum value in the array
  const minValue = min(arr)
  return {
    allSame: false,
    minValue: minValue
  }
}

const ConvertBaseProductItem = (
  product: AlgoliaPLPTypings.AllSkuProperty | AlgoliaPLPTypings.ProductItem
): BaseProductItem => {
  const {
    sticker,
    price,
    easyPayOption,
    listPrice,
    skuId,
    displayName,
    imageId,
    seoUrl = `/product-detail-page?skuId=${skuId}`,
    priceVisibility
  } = product

  const topSticker = find(sticker, { type: 'top' })
  const middleSticker = find(sticker, { type: 'middle' })
  const bottomSticker = find(sticker, { type: 'bottom' })
  const easyPayOptions = convertEasyPayOptions(easyPayOption)

  return {
    bottomStickerClass: bottomSticker?.className,
    bottomStickerName: bottomSticker?.name,
    easyPayOptions,
    topStickerClass: topSticker?.className,
    topStickerName: topSticker?.name,
    middleStickerClass: middleSticker?.className,
    middleStickerName: middleSticker?.name,
    price,
    easyPaySkuId: isEmpty(easyPayOption) ? '' : skuId,
    wasPrice: listPrice > price ? listPrice : undefined,
    skuId,
    displayName,
    imageId,
    linkUrl: seoUrl,
    thumb: `${IMAGE_PREFIX}MMGS7/${imageId}-00-120x120.jpg`,
    priceVisibility
  }
}

export const convertAlgoliaProdItem2ATG = (
  product: AlgoliaPLPTypings.ProductItem
): PLPTypings.IProductItem => {
  const {
    identifiers,
    productId,
    review,
    allSkuProperties,
    storeName,
    condition,
    priceVisibility
  } = product

  const baseProductItem = ConvertBaseProductItem(product)

  const usedCondition = !isEmpty(condition?.lvl1)
    ? condition?.lvl1?.split('>')?.[1]
    : undefined

  const { normalStyleOptions, openBoxStyleOptions = [] } = groupBy(
    allSkuProperties,
    skuProperties => {
      if (includes(openBoxConditions, skuProperties.condition)) {
        return 'openBoxStyleOptions'
      }
      if (!skuProperties.condition || skuProperties.condition === 'New') {
        return 'normalStyleOptions'
      }
      return 'otherStyleOptions'
    }
  )

  if (product.outletSub && includes(openBoxConditions, product.outletSub)) {
    openBoxStyleOptions.push({
      condition: product.outletSub,
      price: product.price,
      seoUrl: product.seoUrl,
      skuId: product.skuId
    } as AlgoliaPLPTypings.AllSkuProperty)
  }

  const productItem = {
    ...baseProductItem,
    productEntId: identifiers.productEntId,
    productId,
    rating: review.overallRating,
    reviews: review.totalReviews,
    openBoxStyleOptions,
    styleOptions: normalStyleOptions?.map(property =>
      ConvertBaseProductItem({ ...property, priceVisibility })
    ),
    storeName: !isEmpty(usedCondition) ? storeName : undefined,
    usedCondition
  }

  // For items with multiple SKUs and different prices, From $xxx is displayed by default, and the was price is not displayed
  if (!isEmpty(normalStyleOptions)) {
    const priceArr = normalStyleOptions?.map(sku => sku.price)
    const result = checkArraySameAndFindMin(priceArr)
    if (!result.allSame) {
      productItem.price = `From ${formatCurrency(result.minValue!, '$')}` as any
      productItem.wasPrice = undefined
    }
  }

  return productItem
}
