import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { triggerChatButton } from '@gc/common-lib/utils/liveChat'

const CallOrChat = ({ actionId }) => {
  const userInfo = useHeaderUserInfo()
  const phone = userInfo?.callCenterNumber
  return (
    <div
      className="h3 mx-auto max-w-[1024px] pt-[5%] pb-[3%] mb-5 text-center"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'CallOrChat'}
    >
      Call{' '}
      <a
        className="text-[#e7131a] underline"
        href={`tel:${phone ?? '866-498-7882'}`}
      >
        {phone ? phone : '866-498-7882'}
      </a>{' '}
      or{' '}
      <button
        onClick={() => triggerChatButton()}
        className="text-[#e7131a] underline"
      >
        chat
      </button>{' '}
      to save on orders of $199+
    </div>
  )
}

export default CallOrChat
