import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import Image from '@gc/ui-components/image'
import { Swiper } from '@gc/ui-components/swiper'
import { useMount, useUpdate } from 'ahooks'
import classnames from 'classnames'
import type { PropsWithChildren, ReactNode } from 'react'
import React, { useRef } from 'react'

interface ISwiperWithNavigationLayoutProps {
  swiperConfig: any
  btnClassName?: string
  swiperBtnContent?: ReactNode
  swiperBtnSize?: number
  hideNavWhenDisabled?: boolean
  hideNavForMobile?: boolean
  swiperContainerClassName?: string
  wrapSwiperClassName?: string
}

export const SwiperWithNavigationLayout: React.FC<
  PropsWithChildren<ISwiperWithNavigationLayoutProps>
> = ({
  children,
  swiperConfig,
  btnClassName = '',
  swiperBtnSize = 36,
  swiperBtnContent = (
    <Image
      src="/assets/icons/plp/chevron-left.svg"
      alt="swiper button"
      width={swiperBtnSize}
      height={swiperBtnSize}
    />
  ),
  hideNavWhenDisabled = true,
  hideNavForMobile = true,
  swiperContainerClassName = 'lg:mx-6',
  wrapSwiperClassName = ''
}) => {
  const prevBtn = useRef<HTMLButtonElement>(null)
  const nextBtn = useRef<HTMLButtonElement>(null)

  const swiperButton = {
    left: (
      <button
        className={classnames(
          `prev-btn ${hideNavForMobile ? 'hidden' : ''} lg:flex items-center justify-center ${hideNavWhenDisabled ? 'lg:disabled:hidden' : 'lg:disabled:opacity-35'}  rounded absolute z-10 left-0 top-[20%] drop-shadow-md`,
          btnClassName
        )}
        ref={prevBtn}
        style={{ width: swiperBtnSize, height: swiperBtnSize }}
      >
        {swiperBtnContent}
      </button>
    ),
    right: (
      <button
        className={classnames(
          `next-btn w-[${swiperBtnSize}px] h-[${swiperBtnSize}px] ${hideNavForMobile ? 'hidden' : ''} lg:flex items-center justify-center ${hideNavWhenDisabled ? 'lg:disabled:hidden' : 'lg:disabled:opacity-35'} rounded absolute z-10 right-0 top-[20%] rotate-180`,
          btnClassName
        )}
        ref={nextBtn}
        style={{ width: swiperBtnSize, height: swiperBtnSize }}
      >
        {swiperBtnContent}
      </button>
    )
  }

  useMount(useUpdate())

  return (
    <div className={classnames('relative max-w-full', wrapSwiperClassName)}>
      {swiperButton.left}
      <div className={swiperContainerClassName}>
        <Swiper
          {...swiperConfig}
          navigation={{
            prevEl: prevBtn.current,
            nextEl: nextBtn.current
          }}
        >
          {children}
        </Swiper>
      </div>
      {swiperButton.right}
    </div>
  )
}

export default withErrorBoundary(SwiperWithNavigationLayout)
