import { map, split, toLower, trim } from 'lodash'

export const monetateApiBaseUrl = process.env
  .NEXT_PUBLIC_MONETATE_API_BASE_URL as string
export const monetateChannel = process.env
  .NEXT_PUBLIC_MONETATE_CHANNEL as string

export const forwardCookieFields = map(
  split(
    process.env.NEXT_PUBLIC_MONETATE_REQUEST_FORWARD_COOKIE_FIELDS || '',
    ','
  ),
  trim
)

export const forwardHeaderFields = map(
  split(toLower(process.env.MONETATE_REQUEST_FORWARD_HEADER_FIELDS || ''), ','),
  trim
)
