import Script from 'next/script'
import { useEffect, useState } from 'react'

interface PreezieProps {
  source: {
    guideKey: string
    version: string
  }
  actionId: string
}

const Preezie: React.FC<PreezieProps> = ({ source, actionId }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isStorybook = process.env.STORYBOOK === 'true'

  useEffect(() => {
    if (isLoaded) {
      // @ts-expect-error - PREEZIE_GUIDE is not defined
      window.PREEZIE_GUIDE.render([
        {
          guideKey: source.guideKey,
          version: source.version,
          renderTo: 'preezie-widget-div-id'
        }
      ])
    }
  }, [isLoaded, source])

  if (!source) return null
  const scriptSrc = `${
    isStorybook ? 'https:' : ''
  }//preeziecdn.azureedge.net/production/preguide.min.js`

  return (
    <>
      <Script
        type="text/javascript"
        src={scriptSrc}
        strategy="lazyOnload" // Lazy load this script
        onReady={() => {
          try {
            setIsLoaded(true)
          } catch (error) {
            console.error('Error rendering Preezie widget', error)
          }
        }}
      />
      {!isLoaded ? (
        <div className="min-h-[627px] w-full mx-auto rounded-lg flex max-w-[1492px] my-5 px-5 pb-5">
          <div className="h-full w-full bg-gray-200 animate-pulse" />
        </div>
      ) : (
        <div
          className="preezie-wrap max-w-[1492px] mx-auto my-5 px-5 pb-5"
          region-id="third-party-widgets"
          id="preezie-widget-div-id"
          data-monetate-action-id={actionId}
          data-monetate-component-name={'Preezie'}
        />
      )}
    </>
  )
}

export default Preezie
