import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

type HeroCTA = {
  altText: string
  ctaText: string
  dateline: string
  heading: string
  href: string
  imageSrc: string
  imageSrcMobile: string
  subheading: string
  tagline: string
  imageObjectPosition?: string
}

type HeroCTAProps = { source: HeroCTA; actionId: string }

const HeroCTA: React.FC<HeroCTAProps> = ({ source, actionId }) => {
  const {
    altText,
    ctaText,
    dateline,
    heading,
    href,
    imageObjectPosition,
    imageSrc,
    imageSrcMobile,
    subheading,
    tagline
  } = source
  return (
    <Link
      href={href}
      className="relative flex flex-col legacy:flex-row items-center legacy:h-[488px] legacy:max-h-[488px] mx-auto max-w-[1500px] mb-5"
      data-monetate-action-id={actionId}
      data-monetate-component-name="HeroCTA"
    >
      <Image
        src={imageSrcMobile}
        alt={altText}
        width={768}
        height={574}
        priority
        loading="eager"
        className="legacy:hidden w-full"
      />
      <Image
        src={imageSrc}
        alt={altText}
        priority
        loading="eager"
        fill
        className={`hidden legacy:block object-cover ${
          imageObjectPosition ?? 'object-right-bottom'
        }`}
      />
      <div className="bg-[#161616] legacy:!bg-transparent p-6 z-10 flex flex-col gap-1 legacy:gap-2 justify-center text-white w-full legacy:w-1/2">
        <div
          role="heading"
          aria-level={2}
          className="text-[2.125rem] leading-9 md:text-4xl md:leading-[2.75rem] legacy:text-[2.75rem] legacy:leading-[3rem] font-black"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div
          role="heading"
          aria-level={3}
          className="text-[1.75rem] leading-9 legacy:text-[2.5rem] legacy:leading-10 font-black"
          dangerouslySetInnerHTML={{ __html: subheading }}
        />
        <p
          className="my-1 legacy:my-2 text-lg font-normal leading-6"
          dangerouslySetInnerHTML={{ __html: tagline }}
        />
        <p
          className="font-bold text-xl legacy:text-2xl legacy:leading-[1.875rem]"
          dangerouslySetInnerHTML={{ __html: dateline }}
        />
        <button
          className="bg-[#E7131A] py-3 legacy:py-4 px-6 rounded mt-1 legacy:mt-3 max-w-fit leading-none text-base"
          dangerouslySetInnerHTML={{ __html: ctaText }}
        />
      </div>
    </Link>
  )
}

export default HeroCTA
