import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

type Category = {
  title: string
  imageSrc: string
  href: string
  description?: string
}

const ShopByCategory: React.FC<{
  categories: Category[]
  square?: boolean
  title: string
  actionId: string
}> = ({ categories, square, title, actionId }) => {
  if (!categories) return
  return (
    <div
      className="flex flex-col mb-0 mx-auto max-w-[1500px]"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'ShopByCategory'}
    >
      <h3 className="h4 md:h3 text-2xl text-textDarkColor text-center mt-[3%] font-bold pl-4 mb-[1.5%]">
        {title}
      </h3>
      <ul
        role="presentation"
        className={`w-full mx-auto grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[repeat(3,1fr)] ${categories.length > 12 ? 'legacy:grid-cols-[repeat(7,1fr)]' : 'legacy:grid-cols-[repeat(6,1fr)]'} gap-4 justify-evenly list-none px-4 legacy:px-1`}
      >
        {categories.map(category => (
          <li key={category.imageSrc}>
            <Link href={category.href}>
              <Image
                className={`border border-[#BBBBBB] rounded w-full`}
                width={square ? 224 : 203}
                height={square ? 224 : 152}
                loading="eager"
                src={specifyImageSize(category.imageSrc, 256)}
                alt={category.title}
              />
              <div className="font-normal text-base pt-2 text-center">
                {category.title}
              </div>
              {category.description && <p>{category.description}</p>}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ShopByCategory
