import Link from '@gc/ui-components/link'

import CarouselCards from './CarouselCards'
import NoClientData from './NoClientData'

export type SlideType =
  | 'TopDeals'
  | 'RecentlyViewedAndMore'
  | 'PriceDropsOnTrendingGear'
  | 'PromoProductSlider'
  | 'Default'

// Map each slide type to its corresponding className
const skeletonClassNames: Record<SlideType, string> = {
  TopDeals:
    'h-[358px] md:h-[261.25px] lg:h-[267px] xl:h-[318px] 2xl:h-[358px] mb-10',
  RecentlyViewedAndMore: 'h-[270px] legacy:h-[374px]',
  PriceDropsOnTrendingGear: 'h-[270px] legacy:h-[392px]',
  PromoProductSlider: 'h-[473.58px] sm:[320px] legacy:h-[374px] xl:[431.59px]',
  Default: 'h-[350px] md:h-[270px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px]'
}

// Function to get the className based on the slide
export function getSkeletonClassName(slide: SlideType): string {
  return skeletonClassNames[slide] || skeletonClassNames['Default']
}

const CarouselSectionSkeleton = ({
  cardsToShow,
  className: innerClassName,
  fullWidth,
  hideTextBars,
  noClientData,
  title,
  viewAllUrl
}: {
  cardsToShow?: number | Record<string, number>
  className: string
  fullWidth?: boolean
  hideTextBars?: boolean
  noClientData?: boolean
  title?: string
  viewAllUrl?: string
}) => {
  return (
    <div
      className={`flex flex-col flex-wrap mt-3 mb-0 mx-auto p-5 lg:px-10 ${fullWidth ? '' : 'max-w-[1492px]'}`}
    >
      {(title || viewAllUrl) && (
        <div className="flex justify-between items-center mb-2">
          {title && (
            <h3 className="h4 md:h3 text-textDarkColor font-bold">{title}</h3>
          )}
          {viewAllUrl && (
            <Link
              href={viewAllUrl}
              className="font-semibold text-base text-[#E7131A] hover:underline flex items-center whitespace-nowrap"
            >
              View All
              <svg
                className="ml-1.5"
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 3L12 9L6 15"
                  stroke="#E7131A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </div>
      )}
      {noClientData ? (
        <NoClientData className={innerClassName} />
      ) : (
        <CarouselCards
          cardsToShow={cardsToShow}
          className={innerClassName}
          hideTextBars={hideTextBars}
        />
      )}
    </div>
  )
}

export default CarouselSectionSkeleton
