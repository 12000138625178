import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { map } from 'lodash'

type CLPItem = {
  imageSrc: string
  altText: string
  title: string
  ctaText: string
  href: string
  financing?: string
  description?: string
  price: string
  promo?: string
}

type CLPProps = {
  items: CLPItem[]
}

const CLP: React.FC<CLPProps> = ({ items }) => {
  return (
    <div
      className={`grid grid-cols-2 legacy:grid-cols-4 gap-4 text-center max-w-[1492px] mx-auto py-3 my-5 px-6`}
    >
      {map(items, (item, index) => (
        <div
          className="border rounded flex flex-col gap-2 p-2.5 justify-between"
          key={index}
        >
          <Link className="hover:text-black" href={item.href}>
            <Image
              src={specifyImageSize(item.imageSrc, 300)}
              alt={item.altText}
              width={300}
              height={300}
              sizes="100vw"
              className="w-full h-auto"
            />
            <div
              className={
                item.description
                  ? 'font-semibold text-xl'
                  : 'font-semibold text-base'
              }
            >
              {item.title}
            </div>
            {item.description && (
              <div className="ml-2 text-base">{item.description}</div>
            )}
            {item.price && (
              <div className="text-[24px] legacy:text-[35px] text-[#CD2418] font-bold">
                {item.price}
              </div>
            )}
            {item.promo && (
              <div className="text-[#CD2418] font-semibold text-[15px]">
                {item.promo}
              </div>
            )}
          </Link>
          {item.financing && (
            // item.href is expected to end with ?icid={id}
            <Link
              className="bg-[#f0f0f0] px-1 py-2.5 legacy:p-2.5 border-l-[3px] border-[#7d7d7d] text-black legacy:ml-2"
              href={item.href + '&openFinancingModal=true'}
            >
              {item.financing}{' '}
              <span className=" underline text-bold">Get Details</span>
            </Link>
          )}
          <Link
            className="bg-[#CD2418] font-bold text-base p-2.5 text-white rounded w-full"
            href={item.href}
          >
            {item.ctaText}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default CLP
