import { formatCurrency } from '@gc/common-lib/utils/currency'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

type Deal = {
  imageSrc: string
  description?: string
  href: string
  altText: string
  price: number
  salePrice: number
}

const NewArrivalsSlide: React.FC<Deal & { productIcid: string }> = ({
  imageSrc,
  description,
  href,
  altText,
  price,
  salePrice,
  productIcid
}) => {
  if (!href) return null
  return (
    <Link
      className="flex flex-row flex-wrap justify-start px-7 pb-10"
      href={productIcid ? `${href}?icid=${productIcid}` : href}
      aria-label={altText}
    >
      <Image
        src={specifyImageSize(imageSrc, 180)}
        alt={altText}
        height={180}
        width={180}
        className="basis-full w-full h-auto mx-auto max-w-[180px]"
      />
      <div className="basis-full mb-1 mt-4">
        {salePrice !== price && (
          <div className="py-2 px-2.5 bg-[#e91b23] text-white font-normal text-xs flex w-fit">
            <div className="font-semibold me-1.5">Save:</div>{' '}
            {formatCurrency(price - salePrice, '$')}
          </div>
        )}
      </div>
      <p className={`text-[#2d2d2d] font-semibold mb-2.5 basis-full`}>
        {description}
      </p>
      {price && (
        <p
          className={`${salePrice !== price ? 'text-[#E7131A] line-through' : 'text-[#2d2d2d]'}  font-bold me-3 text-base`}
        >
          {formatCurrency(price, '$')}
        </p>
      )}
      {salePrice !== price && (
        <p className="text-[#2d2d2d] text-base">
          {formatCurrency(salePrice, '$')}
        </p>
      )}
    </Link>
  )
}
export default NewArrivalsSlide
