import { useResponsive } from 'ahooks'
import { isNil, isNumber } from 'lodash'
import { useEffect, useState } from 'react'

type NumberOfCards = number | Record<string, number>

function getNumberOfCardsToShow(
  cardsToShow: NumberOfCards | undefined,
  activeBreakpoint: string | null
): number {
  const DEFAULT_CARDS_TO_SHOW = 5

  if (isNil(cardsToShow)) {
    return DEFAULT_CARDS_TO_SHOW
  }

  if (isNumber(cardsToShow)) {
    return cardsToShow
  }

  const breakpointsOrder = ['2xl', 'xl', 'large', 'legacy', 'middle', 'small']

  let breakpointIndex = breakpointsOrder.indexOf(activeBreakpoint || '')

  if (breakpointIndex === -1) {
    breakpointIndex = 0
  }

  // Loop from the current breakpoint down to the smallest
  for (let i = breakpointIndex; i < breakpointsOrder.length; i++) {
    const bp = breakpointsOrder[i]
    if (cardsToShow[bp] !== undefined) {
      return cardsToShow[bp]
    }
  }

  // If no breakpoint matches, return the first defined value or the default
  return Object.values(cardsToShow)[0] || DEFAULT_CARDS_TO_SHOW
}

const CarouselCards: React.FC<{
  cardsToShow?: NumberOfCards
  className: string
  hideTextBars?: boolean
}> = ({ cardsToShow, className, hideTextBars }) => {
  const responsive = useResponsive()

  const [numberOfCards, setNumberOfCards] = useState(5)

  useEffect(() => {
    if (responsive) {
      const activeBreakpoint =
        Object.keys(responsive)
          .reverse() // Reverse the order of the keys
          .find(key => responsive[key]) || 'unknown'

      setNumberOfCards(getNumberOfCardsToShow(cardsToShow, activeBreakpoint))
    }
  }, [responsive])

  return (
    <div className={`flex gap-4 ${className}`}>
      {[...Array(numberOfCards)].map((_, index) => (
        <div
          key={index}
          className="w-full max-w-sm py-4 rounded-lg animate-pulse flex flex-col h-full space-y-2"
        >
          <div
            className={`bg-gray-200 rounded-xl ${hideTextBars ? 'h-full' : 'h-[60%]'} mb-2`}
          ></div>
          {!hideTextBars && (
            <>
              <div className="bg-gray-200 rounded-xl w-3/4 h-[6%]"></div>
              <div className="bg-gray-200 rounded-xl w-full h-[5%]"></div>
              <div className="bg-gray-200 rounded-xl w-4/5 h-[5%]"></div>
              <div className="bg-gray-200 rounded-xl w-1/3 h-[5%]"></div>
            </>
          )}
          <span className="sr-only">Loading...</span>
        </div>
      ))}
    </div>
  )
}

export default CarouselCards
