import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'

type Promo = {
  altText: string
  href: string
  imageSrc: string
  linkText?: string
  title?: string
  productIcid?: string
}

const PromoProductCard: React.FC<Promo> = ({
  altText,
  href,
  imageSrc,
  linkText,
  title,
  productIcid
}) => {
  return (
    <Link
      className="flex flex-col border border-[#bbbbbb] rounded pb-3 group/item"
      href={productIcid ? `${href}?icid=${productIcid}` : href}
      aria-label={altText}
    >
      <div className="w-full relative overflow-hidden">
        <Image
          src={imageSrc}
          alt={altText}
          width={300}
          height={250}
          className="object-cover w-full"
        />
      </div>

      <p className="text-textDarkColor font-bold leading-5 px-2 h-[60px] text-base mb-2.5 mt-7 group-hover/item:underline line-clamp-3">
        {title}
      </p>
      <div className="text-[#e91b23] px-2.5 font-semibold text-base underline">
        {linkText}
      </div>
    </Link>
  )
}

export default PromoProductCard
