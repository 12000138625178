import { QUERY_KEY_HOMEPAGE_DAILY_PICK } from '@constants/query/home'
import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import ATGService from '@gc/common-lib/api/request'
import { logger } from '@gc/common-lib/utils/logger'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { useQuery } from '@tanstack/react-query'

const DailyPick = ({ source, actionId }: any) => {
  const isStorybook = process.env.STORYBOOK === 'true'

  const { url, params } = source.query ?? {}
  const queryResult = useQuery({
    queryKey: [QUERY_KEY_HOMEPAGE_DAILY_PICK, url, params],
    queryFn: () =>
      ATGService({ url, params, method: 'get' })
        .then(result => result.data)
        .catch(error => {
          logger.error({
            error,
            message: 'Get Daily pick data request from ATG API failed'
          })
          return null
        }),
    enabled: Boolean(source.query) && !isStorybook
  })

  const data = isStorybook ? source : (queryResult.data ?? {})
  const isPending = isStorybook ? false : queryResult.isPending

  if (isPending) {
    return (
      <div className="h-[440px] w-full max-w-[1492px] mx-auto rounded-lg flex p-5 lg:px-10">
        <div className="h-full w-full bg-gray-200 animate-pulse" />
      </div>
    )
  }

  if (data?.emPageData?.Section) {
    const Section = data.emPageData.Section

    // Find mobile image and products section
    const mobileImg = Section.find(
      section => section.cartridge === 'MobileImage'
    )
    const productsSection = Section.find(
      section => section.cartridge === 'MobileTargetter'
    )

    // Check if both mobile image and products data are missing
    if (!mobileImg?.imageUrl || !productsSection?.products?.length) {
      return null
    }

    const products = productsSection.products

    const formatData = {
      title: products[0].displayName,
      marketingImageSrc: source.marketingImageSrc,
      marketingMobileImageSrc: source.marketingMobileImageSrc,
      marketingTabletImageSrc: source.marketingTabletImageSrc,
      featuredDealsLink:
        source.featuredDealsLink || '/Daily-Pick.gc?icid=LP6669',
      altTextMarketingImageSrc: mobileImg.imageAlt || '',
      pick: {
        altText: mobileImg.imageAlt || products[0].displayName,
        href: products[0].path,
        imageSrc: products[0].thumb,
        name: products[0].displayName,
        price: products[0].regularPrice,
        salePrice: products[0].price,
        savings: products[0].savings
      }
    }

    return (
      <div
        className="max-w-[1492px] mx-auto"
        data-monetate-action-id={actionId}
        data-monetate-component-name={'DailyPick'}
      >
        <div className="flex flex-col legacy:flex-row legacy:flex-wrap p-5 mb-5">
          <Link
            className="flex w-full legacy:w-auto"
            href={formatData.featuredDealsLink}
          >
            <picture className="w-full">
              <source
                media={`(max-width: 667px)`}
                srcSet={formatData.marketingMobileImageSrc}
              />
              <source
                media={`(min-width: 668px) and (max-width: ${GC_BREAK_POINTS_CONFIG.legacy - 1}px)`}
                srcSet={formatData.marketingTabletImageSrc}
              />
              <source
                media={`(min-width: ${GC_BREAK_POINTS_CONFIG.legacy}px)`}
                srcSet={formatData.marketingImageSrc}
              />
              <Image
                height={337}
                width={213}
                src={formatData.marketingImageSrc}
                alt={formatData.altTextMarketingImageSrc}
                className="w-full legacy:w-auto legacy:max-h-[318px] lg:max-h-[341px]"
              />
            </picture>
          </Link>
          <div className="flex flex-col py-[.5%] pr-[10%] pl-[4%] legacy:p-0 sm:flex-row items-center justify-center border-[#ccc] legacy:border-t border-s legacy:border-s-0 border-e border-b flex-1">
            <div className="w-full sm:w-[32.5%] sm:me-[2.5%] legacy:me-[6.5%] legacy:mb-0">
              <Image
                height={250}
                width={250}
                className="stretch mx-auto w-[250px] sm:max-w-[225px] legacy:max-w-[250px] h-[250px] sm:max-h-[225px] legacy:max-h-[250px]"
                src={specifyImageSize(formatData.pick.imageSrc, 250)}
                alt={formatData.pick.altText}
              />
            </div>
            <div className="flex flex-col w-full sm:w-[52.5%] sm:ms-[2.5%] mb-[3%] legacy:mb-0 sm:flex-row max-sm:items-center text-center sm:text-start flex-wrap">
              <div className="border border-[#333] group-hover:text-[#2d2d2d] font-medium py-[5px] px-2.5 mb-2.5 text-[19px] w-fit">
                Save: {formatData.pick.savings}
              </div>
              <Link
                href={formatData.pick.href}
                className="basis-full text-xs font-normal text-[#333] hover:underline"
              >
                {formatData.pick.name}
              </Link>
              <div className="flex flex-col sm:flex-row items-center justify-center sm:gap-x-[40px]">
                <p className="basis-auto text-[46px] font-bold text-[#cc0000] mx-[5px]">
                  {formatData.pick.salePrice}
                </p>
                <dl className="text-[#b5b3b3] text-base font-semibold whitespace-nowrap">
                  <dt>Regular Price:</dt>
                  <dd>{formatData.pick.price}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default DailyPick
