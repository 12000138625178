import HeaderLayout from '@bc/algolia-plp/header/HeaderLayout'
import Footer from '@bc/footer'
import { HomeLayout } from '@bc/homepage/layout'
import NewHeaderLayout from '@bc/new-header'
import { REACT_HOMEPAGE_LAYOUT_REDIS_KEY } from '@constants/redis-keys'
import { QUERY_KEY_HEADER_FOOTER } from '@gc/common-lib/constants/global/queryKey'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { getRedisData } from '@gc/common-lib/server/redis/client'
import { convertCookieToObj } from '@gc/common-lib/utils/browser'
import { logger } from '@gc/common-lib/utils/logger'
import { setGCIDigitalData } from '@gc/common-lib/utils/window'
import GlobalSpin from '@gc/ui-components/spin/GlobalSpin'
import {
  applyClientSideActions,
  requestMonetateActionsClientSide
} from '@helper/utils/monetate'
import { useGlobalHeaderMenuData } from '@hooks/global/new-header'
import { setNewHeaderQuery } from '@server/header'
import { generateHomePageLayout } from '@server/home'
import { requestMonetateActions } from '@server/monetate'
import { getHeaderFooterDataWithRedis } from '@server/request-with-redis'
import { type RootState } from '@store/index'
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { groupBy, isEmpty } from 'lodash'
import Head from 'next/head'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

export default function IndexPage(props) {
  const { trackEvent } = useTracking()
  const loading = useSelector((state: RootState) => state.global.globalLoading)
  const { data: headerMenu } = useGlobalHeaderMenuData()
  const hasNewHeaderMenu = !isEmpty(headerMenu)

  const { identityLoggedUser = {} as any, isLoading } = useHeaderUserInfo()

  const { id: sessionId, hashedEmail, ATGID } = identityLoggedUser

  const { data: clientSideActions } = useQuery({
    queryKey: ['clientSideMonetateActions'],

    queryFn: () =>
      requestMonetateActionsClientSide({
        pageType: 'react-home'
      }),

    refetchOnWindowFocus: false,
    retry: false
  })

  const updatedLayout = clientSideActions
    ? applyClientSideActions(props.layout, clientSideActions)
    : props.layout

  useEffect(() => {
    if (!isLoading && ATGID) {
      const omnitureData = {
        siteSection: 'home',
        reportSuiteIds: 'guitarcenterprod',
        pageType: 'home',
        countryName: 'united states',
        pageName: 'home',
        ATGID,
        sessionId,
        hashedEmail
      }
      setGCIDigitalData('omnitureData', omnitureData)

      trackEvent({
        eventName: 'omnitureLoaded',
        omnitureLoaded: { pageLocation: 'home', omnitureData }
      })
    }
  }, [ATGID, isLoading, hashedEmail])

  useEffect(() => {
    trackEvent({
      pageLocation: 'home',
      eventName: 'homePageLoaded'
    })

    setTimeout(() => {
      trackEvent({
        pageLocation: 'home',
        eventName: 'cookieLoaded',
        cookieLoaded: {
          cookie: convertCookieToObj()
        }
      })
    }, 2000)
  }, [])

  const headerBottomActiveArray = useSelector(
    (state: RootState) => state.header.headerBottomActiveArray
  )
  return (
    <>
      <Head>
        <title>
          Guitar Center: Your Music Store for Instruments, Accessories and
          Equipment
        </title>
        <meta
          name="description"
          content="Guitar Center is the world's largest musical instruments store. Shop Guitars, Bass, Drums, Amps, DJ, Keyboards, Pro-Audio and more. Most orders ship free!"
        />
        <link
          rel="canonical"
          href="https://www.guitarcenter.com"
          key="canonical"
        />
      </Head>
      <div
        className={classNames(
          'basic-layout',
          headerBottomActiveArray.length > 0 && 'old-header-overlay'
        )}
      >
        {hasNewHeaderMenu ? <NewHeaderLayout /> : <HeaderLayout />}
        <HomeLayout layout={updatedLayout} />
        <Footer />
      </div>
      {loading && <GlobalSpin />}
    </>
  )
}

export const getServerSideProps = async context => {
  const { req, query } = context

  const queryClient = new QueryClient()

  await queryClient.prefetchQuery({
    queryKey: [QUERY_KEY_HEADER_FOOTER],
    queryFn: () => getHeaderFooterDataWithRedis()
  })

  const actions = await requestMonetateActions({
    pageType: 'react-home',
    request: req,
    variation: query.variation
  })

  const {
    pageLayout: pageLayoutActions,
    pageData: pageDataActions,
    template
  } = groupBy(actions, 'actionDest')

  if (!template?.[0]?.source) {
    logger.error({
      message: "react homepage can't get template from monetate",
      JSESSIONID: req?.cookies?.JSESSIONID,
      USER_ID: req?.cookies?.DYN_USER_ID
    })

    const { redisValue } = await getRedisData(REACT_HOMEPAGE_LAYOUT_REDIS_KEY)

    if (redisValue) {
      const layout = JSON.parse(redisValue)
      return {
        props: { layout, actions, dehydratedState: dehydrate(queryClient) }
      }
    }

    logger.error({
      message:
        "Dangerous Error --- react homepage can't get template from monetate or Redis cache",
      JSESSIONID: req?.cookies?.JSESSIONID,
      USER_ID: req?.cookies?.DYN_USER_ID
    })
    return { notFound: true }
  }

  await setNewHeaderQuery({ monetateActions: actions, queryClient, req })

  const layout = await generateHomePageLayout({
    req,
    pageLayoutActions,
    pageDataActions,
    template
  })

  return { props: { layout, actions, dehydratedState: dehydrate(queryClient) } }
}
