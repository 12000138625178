import SwiperWithNavigationLayout from '@bc/algolia-endeca/template/_SwiperWithNavigationLayout'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { SwiperSlide } from '@gc/ui-components/swiper'

type Slide = {
  imageSrc: string
  altText: string
  title: string
  description: string
  buttonText: string
  href: string
}

type HeroSliderProps = {
  slides: Slide[]
  actionId: string
}

const HeroSlider: React.FC<HeroSliderProps> = ({ slides, actionId }) => {
  return (
    <div
      className="relative border-t border-[#e7e7e7] mx-auto ps-[.563rem] 2xl:ps-0"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'HeroSlider'}
    >
      <SwiperWithNavigationLayout
        swiperConfig={{
          spaceBetween: 4,
          breakpoints: {
            480: {
              slidesPerView: 1.25,
              slidesPerGroup: 1
            },
            767: {
              slidesPerView: 1.5,
              slidesPerGroup: 1
            },
            1024: {
              slidesPerView: 3.25,
              slidesPerGroup: 3.25
            }
          }
        }}
        swiperBtnSize={50}
        swiperBtnContent={
          <>
            <div className="cursor-pointer text-white font-bold text-2xl transition-[0.6s] duration-[ease] select-none bg-[rgba(0,0,0,0.5)] p-4 rounded-[3px] hidden md:flex">
              {'\u276E'}
            </div>
          </>
        }
        hideNavWhenDisabled={false}
        btnClassName={'top-[50%] mx-1'}
        swiperContainerClassName={''}
      >
        {slides?.map((slide, index) => (
          <SwiperSlide key={index} className="h-auto">
            <Link
              className="flex flex-col h-full p-1.5 px-1 hover:underline hover:text-[#2d2d2d]"
              href={slide.href}
            >
              <div className="flex flex-col h-full">
                <Image
                  className="w-full"
                  alt={slide.altText}
                  height={345}
                  src={slide.imageSrc}
                  width={524}
                  loading="eager"
                />
                <div className="flex-1 relative flex flex-col bg-[#eee] p-[.938rem] tracking-tight shadow-[0_2px_5px_rgba(0,0,0,0.2)]">
                  <div className="h4 md:h3 font-bold pb-10 hover:underline hover:text-[#2d2d2d]">
                    {slide.title}
                    <p className="font-normal mt-2.5 text-base">
                      {slide.description}
                    </p>
                  </div>
                  <button className="absolute left-2 bottom-2 bg-[#e91c22] transition-[background-color] duration-[0.3s] ease-[ease] text-white px-[.938rem] py-2.5 rounded-[5px] text-xs font-normal w-fit">
                    {slide.buttonText}
                  </button>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </SwiperWithNavigationLayout>
    </div>
  )
}

export default HeroSlider
