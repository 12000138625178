import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { Swiper, SwiperSlide } from '@gc/ui-components/swiper'
import { useRef } from 'react'
import type { Swiper as SwiperType } from 'swiper'

import NoClientData from '../skeletons/NoClientData'

type Article = {
  href: string
  altText: string
  imgSrc: string
  title: string
}

type ArticlesProps = {
  title: string
  viewAllUrl: string
  source: Article[]
  actionId: string
}

const Articles: React.FC<ArticlesProps> = ({
  title,
  viewAllUrl,
  source,
  actionId
}) => {
  const swiperRef = useRef<SwiperType>(null)

  if (!isArrayAndNotEmpty(source))
    return (
      <NoClientData className="w-full max-w-[1452px] mx-auto h-[280px] legacy:h-[310px] mb-10 rounded-none" />
    )

  return (
    <article
      className="relative flex-col mt-5 mx-5 min-[1452px]:mx-auto pt-5 legacy:px-10 max-w-[1452px] border-t legacy:border border-[#bbbbbb] mb-10"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'Articles'}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-base legacy:text-2xl legacy:font-bold">{title}</h2>
        {viewAllUrl && (
          <Link href={viewAllUrl}>
            <span className="text-red-500 font-semibold text-lg">View All</span>
          </Link>
        )}
      </div>
      <Swiper
        className="pb-6"
        loop={false}
        spaceBetween={50}
        slidesPerView={2}
        slidesPerGroup={2}
        breakpoints={{
          768: { slidesPerView: 3, slidesPerGroup: 3 },
          [GC_BREAK_POINTS_CONFIG.legacy]: {
            slidesPerView: 5,
            slidesPerGroup: 5
          },
          1280: { slidesPerView: 6, slidesPerGroup: 6 }
        }}
        freeMode={true}
        pagination={{ clickable: true }}
        direction="horizontal"
        onBeforeInit={swiper => {
          // @ts-expect-error
          swiperRef.current = swiper
        }}
        style={
          {
            '--swiper-pagination-color': '#cd2418',
            '--swiper-pagination-bullet-inactive-color': '#999999',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-size': '12px',
            '--swiper-pagination-bullet-horizontal-gap': '6px',
            '--swiper-pagination-bottom': '0'
          } as React.CSSProperties
        }
      >
        {source.map((article, index) => (
          <SwiperSlide key={index}>
            <Link href={article.href}>
              <Image
                src={specifyImageSize(article.imgSrc, 200)}
                alt={article.altText}
                width={190}
                height={100}
                sizes="100vw"
                className="w-full h-auto"
              />
              <p className="mt-2 text-[.813rem] font-normal legacy:text-[15px] text-[#333] legacy:font-semibold group-hover:underline">
                {article.title}
              </p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </article>
  )
}

export default Articles
