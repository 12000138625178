import SwiperWithNavigationLayout from '@bc/algolia-endeca/template/_SwiperWithNavigationLayout'
import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Link from '@gc/ui-components/link'
import { SwiperSlide } from '@gc/ui-components/swiper'

import { mapAlgoliaHits } from '../algolia-product-carousel/server'
import NewArrivalsSlide from '../slide-templates/NewArrivalsSlide'

const NewArrivals = ({ source, actionId, productIcid }) => {
  if (!source?.results) return null

  return (
    <div
      className="max-w-[1492px] mx-auto p-5"
      data-monetate-action-id={actionId}
      data-monetate-component-name={'NewArrivals'}
    >
      <div className="flex flex-row flex-wrap bg-gradient-to-t justify-between items-center from-[#FF4F5D] from-15.09% via-[#E7131A] via-46.05% to-[#780102] to-100% sm:py-[3%] legacy:p-[3%] mt-[.3%]">
        <div className="flex flex-col legacy:flex-row w-full justify-between items-center my-[3%] legacy:my-0">
          <div className="flex-1 text-center px-[20%] legacy:px-0 legacy:mb-2 flex ">
            <Image
              src={specifyImageSize(source.headingImageSrc, 349)}
              alt={source.headingImageSrcAltText}
              width={349}
              height={224}
              className="w-full legacy:w-[60%]  mx-auto mb-[3%]"
            />
          </div>
          <iframe
            src={source.youtubeUrl}
            className="w-full px-6 legacy:mb-0 legacy:px-0 legacy:w-[702px] aspect-video place-self-end"
            title="New Arrivals"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={false}
          />
        </div>
        <SwiperWithNavigationLayout
          swiperConfig={{
            className: 'bg-white p-5 px-7 legacy:mt-3',
            spaceBetween: 20,
            loop: true,
            breakpoints: {
              0: {
                slidesPerView: 1
              },
              667: {
                slidesPerView: 3
              },
              [GC_BREAK_POINTS_CONFIG.legacy]: {
                slidesPerView: 4
              },
              1100: {
                slidesPerView: 5
              }
            }
          }}
          swiperBtnSize={48}
          swiperBtnContent={
            <Image
              src="/assets/icons/homepage/swiper-arrow.svg"
              alt="next"
              width={48}
              height={48}
              priority={true}
            />
          }
          btnClassName={'top-[40%] px-1'}
          hideNavForMobile={false}
          wrapSwiperClassName="flex-1"
        >
          {mapAlgoliaHits(source.results.hits)?.map((data, i) => (
            <SwiperSlide key={i}>
              <NewArrivalsSlide
                key={i}
                imageSrc={data.imageLink}
                description={data.title}
                href={data.link}
                altText={data.altText}
                price={data.price}
                salePrice={data.salePrice}
                productIcid={productIcid}
              />
            </SwiperSlide>
          ))}
        </SwiperWithNavigationLayout>
        <div className="basis-full flex justify-center bg-white sm:bg-transparent">
          <Link
            href={source.href}
            className="mx-auto text-white bg-[#E7131A] sm:bg-white font-semibold text-base text-center sm:text-[#E7131A] mt-[1.5%] px-3 py-4 rounded border-2 border-[#E7131A]"
          >
            Shop All New Arrivals
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewArrivals
