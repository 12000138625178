import type { HomepageCarouselSectionProps } from '@bc/homepage/carousel-section'
import HomepageCarouselSection from '@bc/homepage/carousel-section'
import Articles from '@bc/homepage/sections/Articles'
import CallOrChat from '@bc/homepage/sections/CallOrChat'
import FullWidthAsset from '@bc/homepage/sections/FullWidthAsset'
import HeroSlider from '@bc/homepage/sections/HeroSlider'
import LocalStore from '@bc/homepage/sections/LocalStore'
import NewArrivals from '@bc/homepage/sections/NewArrivals'
import PromoSlider from '@bc/homepage/sections/PromoSlider'
import Services from '@bc/homepage/sections/Services'
import ShopByCategory from '@bc/homepage/sections/ShopByCategory'
import WhyShopAtGuitarCenter from '@bc/homepage/sections/WhyShopAtGuitarCenter'
import { logger } from '@gc/common-lib/utils/logger'
import Image from '@gc/ui-components/image'
import dynamic from 'next/dynamic'

import ClientAlgoliaProductCarousel from './algolia-product-carousel/client'
import ServerAlgoliaProductCarousel from './algolia-product-carousel/server'
import CLP from './sections/CLP'
import DailyPick from './sections/DailyPick'
import HeroCTA from './sections/HeroCTA'
import HeroTiles from './sections/HeroTiles'
import MosaicGrid from './sections/MosaicGrid'
import Preezie from './sections/Preezie'
import ScrollTiles from './sections/ScrollTiles'
import CarouselSectionSkeleton, {
  getSkeletonClassName
} from './skeletons/CarouselSectionSkeleton'

const CountdownTimer = dynamic(() => import('./sections/CountdownTimer'), {
  ssr: false
})
const RichRelevanceProduct = dynamic(
  () => import('@bc/rich-relevance/RichRelevanceProduct'),
  {
    ssr: false
  }
)

const components = {
  CLP: ({ source, actionId }) => (
    <div
      data-monetate-action-id={actionId}
      data-monetate-component-name={'CLP'}
    >
      <CLP items={source} />
    </div>
  ),
  MosaicGrid: ({ source, actionId, title }) => (
    <div
      data-monetate-action-id={actionId}
      data-monetate-component-name={'MosaicGrid'}
    >
      <MosaicGrid items={source} actionId={actionId} title={title} />
    </div>
  ),
  ServerAlgoliaProductCarousel: ({
    carouselSettings,
    source,
    title,
    slide,
    viewAllUrl,
    actionId,
    productIcid
  }: HomepageCarouselSectionProps) => (
    <ServerAlgoliaProductCarousel
      carouselSettings={carouselSettings}
      title={title}
      source={source}
      slide={slide}
      viewAllUrl={viewAllUrl}
      actionId={actionId}
      productIcid={productIcid}
    />
  ),
  ClientAlgoliaProductCarousel: ({
    carouselSettings,
    source,
    title,
    slide,
    viewAllUrl,
    actionId,
    productIcid
  }: HomepageCarouselSectionProps) => (
    <ClientAlgoliaProductCarousel
      carouselSettings={carouselSettings}
      title={title}
      slide={slide}
      source={source}
      viewAllUrl={viewAllUrl}
      actionId={actionId}
      productIcid={productIcid}
    />
  ),
  Html: ({ source, actionId }) => (
    <section
      dangerouslySetInnerHTML={{ __html: source }}
      data-monetate-action-id={actionId}
      data-monetate-component-name={'HTML'}
    />
  ),
  HeroSlider: ({ source, actionId }) => (
    <HeroSlider slides={source} actionId={actionId} />
  ),
  HeroTiles: ({ source, actionId }) => (
    <HeroTiles source={source} actionId={actionId} />
  ),
  HeroCTA: ({ source, actionId }) => (
    <HeroCTA source={source} actionId={actionId} />
  ),
  ScrollTiles: ({ source, actionId }) => (
    <ScrollTiles source={source} actionId={actionId} />
  ),
  CountdownTimer: ({ source, actionId }) => (
    <CountdownTimer source={source} actionId={actionId} />
  ),
  ShopByCategory: ({ source, square, title, actionId }) => (
    <ShopByCategory
      title={title}
      square={square}
      categories={source}
      actionId={actionId}
    />
  ),

  RecentlyViewedAndMore: ({
    title,
    actionId,
    placements,
    pageType,
    breakpoints,
    className,
    itemsCount,
    style,
    productIcid
  }) => (
    <RichRelevanceProduct
      pageType={pageType}
      title={title}
      actionId={actionId}
      placements={placements}
      breakpoints={breakpoints}
      className={className + ` py-5 px-10`}
      itemsCount={itemsCount}
      style={style}
      productIcid={productIcid}
      swiperBtnSize={50}
      swiperBtnContent={
        <Image
          src="/assets/images/pdp/gallery-left-navigation.svg"
          alt="swiper button"
          width={50}
          height={50}
        />
      }
    />
  ),
  HomepageCarouselSection: ({
    carouselSettings,
    source,
    title,
    slide,
    viewAllUrl,
    actionId,
    productIcid
  }: HomepageCarouselSectionProps) => {
    if (!source) {
      return (
        <CarouselSectionSkeleton
          className={getSkeletonClassName(slide)}
          cardsToShow={5}
          title={title}
          viewAllUrl={viewAllUrl}
        />
      )
    }
    return (
      <HomepageCarouselSection
        carouselSettings={carouselSettings}
        source={source.data}
        slide={source.slide ?? slide}
        title={title}
        viewAllUrl={source.viewAllUrl ?? viewAllUrl}
        actionId={actionId}
        productIcid={productIcid}
      />
    )
  },
  RecommendationsCarousel: ({
    carouselSettings,
    source,
    title,
    slide,
    viewAllUrl,
    actionId,
    productIcid
  }: HomepageCarouselSectionProps) => {
    if (!source) {
      return (
        <CarouselSectionSkeleton
          className={getSkeletonClassName(slide)}
          cardsToShow={5}
          title={title}
          viewAllUrl={viewAllUrl}
        />
      )
    }
    return (
      <HomepageCarouselSection
        carouselSettings={carouselSettings}
        source={source}
        slide={source.slide ?? slide}
        title={title}
        viewAllUrl={source.viewAllUrl ?? viewAllUrl}
        actionId={actionId}
        productIcid={productIcid}
      />
    )
  },
  PromoSlider: ({ source, title, actionId }) => {
    if (!source) {
      return null
    }
    return <PromoSlider title={title} products={source} actionId={actionId} />
  },
  NewArrivals: ({ source, actionId, productIcid }) => (
    <NewArrivals
      source={source}
      actionId={actionId}
      productIcid={productIcid}
    />
  ),
  LocalStore: ({ source, actionId }) => (
    <LocalStore {...source} actionId={actionId} />
  ),
  CallOrChat: ({ source, actionId }) => (
    <CallOrChat {...source} actionId={actionId} />
  ),
  FullWidthAsset: ({ source, actionId }) => (
    <FullWidthAsset {...source} actionId={actionId} />
  ),
  Services: ({ source, actionId }) => (
    <Services source={source} actionId={actionId} />
  ),
  Articles: ({ source = {} as any, actionId }) => {
    const { title, viewAllUrl, articles } = source
    return (
      <Articles
        title={title}
        viewAllUrl={viewAllUrl}
        source={articles}
        actionId={actionId}
      />
    )
  },
  WhyShopAtGuitarCenter: ({ source, actionId }) => (
    <WhyShopAtGuitarCenter {...source} actionId={actionId} />
  ),
  DailyPick: ({ source, actionId }) => {
    if (!source) {
      return null
    }
    return <DailyPick source={source} actionId={actionId} />
  },
  Preezie: ({ source, actionId }) => {
    if (!source) return null
    return <Preezie source={source} actionId={actionId} />
  }
}

export const HomeLayout = ({ layout }: { layout: any }) => {
  const { children } = layout

  return (
    <>
      {children.map((section: any, index: number) => {
        // Use 'component' property if available, otherwise fall back to '@type'
        const componentKey = section['component']
        const Component = components[componentKey]

        // If the component doesn't exist in the map, warn and return null
        if (!Component) {
          logger.warn({
            message: `Component not found for key ---->, ${componentKey}`
          })
          return null
        }

        return <Component key={index} {...section} />
      })}
    </>
  )
}
