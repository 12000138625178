import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'

export interface IProductStickerProps {
  stickerType: string
  stickerClass?: string
  className?: string
  stickerLink?: string
  stickerLinkTarget?: boolean
}

function ProductSticker({
  stickerType,
  stickerClass,
  className,
  stickerLink,
  stickerLinkTarget
}: IProductStickerProps) {
  const type = {
    stickerBlackLevel1: 'bg-[#2d2d2d] text-[#FFF]',
    stickerRedLevel1: 'bg-primaryColor text-[#FFF]',
    stickerBlueLevel1: 'bg-[#3378CC] text-[#FFF]',
    stickerOrangeLevel1: 'bg-[#FFA600] text-white',
    // add new sticker for inventory, UPDATED RedLevel2 according Tom T new design
    stickerRedLevel2: 'bg-[#E7131A] text-[#FFF]',
    stickerGreenLevel2: 'bg-[#058000] text-[#FFF] ',
    stickerCustomLevel1: 'bg-[#eee] text-[#2d2d2d]',
    stickerCustomLevel2: 'bg-primaryDarkColor text-[#FFF]',
    stickerCustomLevel3: 'bg-[#FFF2F2] text-primaryDarkColor'
  }

  return (
    <div
      className={`product-sticker flex items-center font-bold text-xs ${className ?? ''} ${
        type[stickerClass ?? '']
      }`}
    >
      {stickerLink ? (
        <a
          href={stickerLink}
          target={stickerLinkTarget ? '_blank' : '_self'}
          className="hover:underline hover:text-inherit"
          rel="noreferrer"
        >
          {stickerType}
        </a>
      ) : (
        <p className="sticker">{stickerType}</p>
      )}
    </div>
  )
}

export default withErrorBoundary(ProductSticker)
